import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./BusStand.module.css";
import NavBar from "../NavBar";
import Footer from "../Footer";

function BusStand() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(
          " https://www.backend.easytripss.com/public/bus/allstops"
        );
        setCities(response.data.data || []);
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, []);

  const formatForUrl = (value) => {
    return value
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-()]/g, ""); // Remove all characters except alphanumeric, hyphens, and parentheses
  };

  const handleCityClick = (cityName) => {
    navigate(`/bus-stand/${formatForUrl(cityName)}`);
  };

  const filteredCities = cities.filter((city) =>
    city.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossorigin="anonymous"
        ></script>
        <meta name="google-adsense-account" content="ca-pub-1396556165266132" />
        
        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>
        <title>
          Find Nearby Bus Stands and Stops - Search by Bus Number | Easytripss
        </title>
        <meta
          name="description"
          content="Find bus stands and stops near you by entering the bus number. Our intuitive search feature provides you with accurate information about nearby bus routes and their stops, making your journey more convenient with Easytripss."
        />
        <meta
          name="keywords"
          content="Bus Stand Finder,Bus Stops Near Me,Find Bus Stands,Locate Bus Stops,Search Bus Number,Nearby Bus Routes,Local Bus Stops,Bus Stand Locations,Bus Route Finder,Track Buses,Bus Stop Search,Nearby Bus Stands and Stops,Bus Number Search,Discover Bus Stops,Bus Stand and Stops Locator"
        />
        <meta name="author" content="Easy Tripss" />
        <meta name="robots" content="index, follow" />

        <link rel="canonical" href={`https://easytripss.com/bus-stand`} />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Find Nearby Bus Stands and Stops - Search by Bus Number | Easytripss"
        />
        <meta
          property="og:description"
          content="Find bus stands and stops near you by entering the bus number. Our intuitive search feature provides you with accurate information about nearby bus routes and their stops, making your journey more convenient with Easytripss."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://easytripss.com/bus-stand"
        />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Find Nearby Bus Stands and Stops - Search by Bus Number | Easytripss"
        />
        <meta
          name="twitter:description"
          content="Find bus stands and stops near you by entering the bus number. Our intuitive search feature provides you with accurate information about nearby bus routes and their stops, making your journey more convenient with Easytripss."
        />
        <meta name="twitter:image" content="https://res.cloudinary.com/dch7mrf5x/image/upload/v1723738453/BusImage/wcolbra2wgoarmmxllzw.png"/>

        {/* Schema Markup for BlogPosting */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://easytripss.com/bus-stand",
            },
            headline:
              "Find Nearby Bus Stands and Stops - Search by Bus Number | Easytripss",
            image: "https://res.cloudinary.com/dch7mrf5x/image/upload/v1723738453/BusImage/wcolbra2wgoarmmxllzw.png",
            author: {
              "@type": "Organization",
              name: "Easy Tripss",
            },
            publisher: {
              "@type": "Organization",
              name: "Easy Tripss",
              logo: {
                "@type": "ImageObject",
                url: "https://easytripss.com/logo.png", // Replace with your logo URL
              },
            },
          })}
        </script>
      </Helmet>
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <NavBar />
      <Box className={styles.container}>
        <Typography variant="h4" className={styles.title}>
          Bus Stands
        </Typography>
        <br/>
        <TextField
          variant="outlined"
          placeholder="Search by city name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={styles.searchBar}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
       <br/>
       <br/>
        {loading ? (
          <CircularProgress className={styles.loader} />
        ) : filteredCities.length > 0 ? (
          <List className={styles.cityList}>
            {filteredCities.map((city, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleCityClick(city)}
                className={styles.cityItem}
              >
                <ListItemText
                  primary={city}
                  classes={{ primary: styles.cityText }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="h6" className={styles.noResults}>
            No cities found.
          </Typography>
        )}
      </Box>
      <Footer />
    </div>
  );
}

export default BusStand;
