import React from "react";
import styles from "./Section1.module.css";
import bus from "../../Images/banner easytrip.webp";

function Section1() {
  return (
    <div className={styles.container}>
      <div className={styles.buses}>
        <img src={bus} alt="easytripss" loading="lazy"  title="easytripss" height="auto" width="auto"/>
      </div>
    </div>
  );
}

export default Section1;
