import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styles from "./ContactUs.module.css";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const ContactUsPage = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(
        " https://www.backend.easytripss.com/public/bus/contactUs",
        formData
      );
      if (response.status === 200) {
        setSuccess("Your message has been sent successfully!");
        setFormData({
          fName: "",
          lName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setError("Failed to send the message. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossOrigin="anonymous"
        ></script>
        <title>
          EasyTripss - Contact Us | Reach Out for Support and Inquiries
        </title>
        <meta
          name="description"
          content="Have questions or need support? Contact EasyTripss today and we'll assist you with your travel plans and inquiries."
        />
        <meta
          name="keywords"
          content="contact us, EasyTripss support, customer service, travel inquiries, contact EasyTripss"
        />
        <link rel="canonical" href="https://easytripss.com/contact-us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EasyTripss - Contact Us | Reach Out for Support and Inquiries"
        />
        <meta
          property="og:description"
          content="Reach out to EasyTripss for any questions or support regarding your travel plans. We are here to help you make the most of your travel experience."
        />
        <meta
          property="og:url"
          content="https://easytripss.com/contact-us"
        />
        <meta
          property="og:image"
          content="https://easytripss.com/contact-us-image.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="EasyTripss - Contact Us | Reach Out for Support and Inquiries"
        />
        <meta
          name="twitter:description"
          content="Need help with your travel plans? Contact EasyTripss today for support and assistance."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/contact-us-image.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            potentialAction: {
              "@type": "SearchAction",
              target:
                "https://easytripss.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            logo: "https://easytripss.com/logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-800-555-1234",
              contactType: "Customer Service",
              areaServed: "US",
              availableLanguage: "English",
            },
            sameAs: [
              "https://www.facebook.com/EasyTripss",
              "https://twitter.com/EasyTripss",
              "https://www.instagram.com/EasyTripss",
            ],
          })}
        </script>
      </Helmet>

      <NavBar />
      <Container maxWidth="md" className={styles.container}>
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h4" component="h1" className={styles.heading}>
            Contact Us
          </Typography>
          <Typography variant="body1" className={styles.subheading}>
            We would love to hear from you! Please fill out this form and we
            will get in touch with you shortly.
          </Typography>

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} className={styles.formGrid}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="fName"
                  value={formData.fName}
                  onChange={handleChange}
                  className={styles.textField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  name="lName"
                  value={formData.lName}
                  onChange={handleChange}
                  className={styles.textField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={styles.textField}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  variant="outlined"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={styles.textField}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className={styles.textField}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={styles.submitButton}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Submit"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>

          {error && (
            <Typography color="error" className={styles.errorMessage}>
              {error}
            </Typography>
          )}
          {success && (
            <Typography color="primary" className={styles.successMessage}>
              {success}
            </Typography>
          )}
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
