import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import styles from './Section3.module.css';
import img1 from "../../Images/42229aa519c8eae542cf4256e9ff5032.webp";
import img2 from "../../Images/b9f91253905ab5ee20cc911114627899.webp";
import img3 from "../../Images/gettyimages-1203842722-1024x1024-removebg-preview 1.webp";
import img4 from "../../Images/pin-48.svg";
import img5 from "../../Images/bank-svgrepo-com.svg";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LocationSearch from '../../Pages/LocationSearch';

const categories = [
  { title: "Bus Routes", image: img1, path: "/bus" },
  { title: "Bus Stands", image: img2 },
  { title: "From - To Buses", image: img3 },
  { title: "PinCode", image: img4 },
  { title: "Find Bank", image: img5 },
];

function Section3() {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [openFromToModal, setOpenFromToModal] = useState(false);
  const [openPincodeModal, setOpenPincodeModal] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false); // New state for Find Bank modal
  const [loading, setLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [pincode, setPincode] = useState(''); 
  const [bankSearchValue, setBankSearchValue] = useState(''); // State for bank search
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCityOptions = async () => {
      try {
        const response = await axios.get(` https://www.backend.easytripss.com/public/bus/allstops`);
        setCityOptions(response.data.data);
      } catch (error) {
        console.error("Error fetching city options:", error);
      }
    };
    fetchCityOptions();
  }, []);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setInputValue('');
    setSelectedCity(null);
  };

  const handleOpenFromToModal = () => {
    setOpenFromToModal(true);
  };

  const handleCloseFromToModal = () => {
    setOpenFromToModal(false);
    setFromValue('');
    setToValue('');
  };

  const handleOpenPincodeModal = () => {
    setOpenPincodeModal(true);
  };

  const handleClosePincodeModal = () => {
    setOpenPincodeModal(false);
    setPincode('');
  };

  const handleOpenBankModal = () => {
    setOpenBankModal(true); // Open Find Bank modal
  };

  const handleCloseBankModal = () => {
    setOpenBankModal(false); // Close Find Bank modal
    setBankSearchValue('');
  };

  const handleNavigate = (category) => {
    if (category.title === "Bus Stands") {
      handleOpenModal();
    } else if (category.title === "From - To Buses") {
      handleOpenFromToModal();
    } else if (category.title === "PinCode") {
      handleOpenPincodeModal();
    } else if (category.title === "Find Bank") {
      handleOpenBankModal(); // Open Find Bank modal
    } else if (category.path) {
      navigate(category.path);
    }
  };

  const formatForUrl = (value) => {
    return value
      .replace(/\s+/g, "-")
      .replace(/[^\w\-()]/g, "");
  };

  const handleSearch = async () => {
    if (!selectedCity) return;

    setLoading(true);

    try {
      const response = await axios.get(` https://www.backend.easytripss.com/public/bus/busesByStops?busStop=${selectedCity}`);
      if (response.status) {
        navigate(`/bus-stand/${formatForUrl(selectedCity)}`);
      }
    } catch (error) {
      console.error("Error fetching bus data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const handleBank = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://www.backend.easytripss.com/public/ifsc/bankData?ifscCode=${bankSearchValue}`);
      if (response.status) {
        navigate(`/bankdetails/${bankSearchValue}`);
      }
    } catch (error) {
      console.error("Error fetching bus data:", error);
      alert("Please Enter Correct IFSC Code");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleFromToSearch = async () => {
    if (!fromValue || !toValue) return;

    setLoading(true);

    try {
      const formattedFrom = formatForUrl(fromValue);
      const formattedTo = formatForUrl(toValue);
      const response = await axios.get(
        ` https://www.backend.easytripss.com/public/bus/getFromTo?from=${encodeURIComponent(fromValue)}&to=${encodeURIComponent(toValue)}`
      );
      if (response.status) {
        navigate(`/bus/${formattedFrom}-to-${formattedTo}`);
      }
    } catch (error) {
      alert("No bus found for this route.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <br />
      <Box className={styles.category_box}>
        <Typography variant="h6" className={styles.heading}>
          All Categories
        </Typography>
        <br />
        <Box className={styles.categories}>
          {categories.map((category, index) => (
            <Box key={index} className={styles.categoryCard} onClick={() => handleNavigate(category)}>
              <img
                src={category.image}
                alt={category.title}
                className={styles.image}
                title={category.title}
                loading="lazy"
                height="auto"
                width="auto"
              />
              <Typography variant="body1" className={styles.title}>
                {category.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Modal Search Box for Bus Stands */}
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Search Bus by Stop</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={cityOptions}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter Bus Stop"
                variant="outlined"
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            )}
            onChange={(event, newValue) => {
              setSelectedCity(newValue);
            }}
            className={styles.searchField}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            className={styles.searchButton}
            fullWidth
            disabled={loading}
            sx={{ marginTop: '20px' }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Search'}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal Search Box for From - To Buses */}
      <Dialog open={openFromToModal} onClose={handleCloseFromToModal} fullWidth maxWidth="sm">
        <DialogTitle>Search Buses by From-To</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={cityOptions}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter From City"
                variant="outlined"
                fullWidth
                value={fromValue}
                onChange={(e) => setFromValue(e.target.value)}
              />
            )}
            onChange={(event, newValue) => {
              setFromValue(newValue);
            }}
            className={styles.searchField}
          />
          <Autocomplete
            options={cityOptions}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter To City"
                variant="outlined"
                fullWidth
                value={toValue}
                onChange={(e) => setToValue(e.target.value)}
              />
            )}
            onChange={(event, newValue) => {
              setToValue(newValue);
            }}
            className={styles.searchField}
            sx={{ marginTop: '20px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFromToSearch}
            className={styles.searchButton}
            fullWidth
            disabled={loading}
            sx={{ marginTop: '20px' }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Search'}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFromToModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal Search Box for PinCode */}
      <Dialog open={openPincodeModal} onClose={handleClosePincodeModal} fullWidth maxWidth="sm">
        <DialogTitle>Search Pincode</DialogTitle>
        <DialogContent>
          <LocationSearch onPlaceSelected={(place) => setPincode(place)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePincodeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Modal Search Box for Find Bank */}
      <Dialog open={openBankModal} onClose={handleCloseBankModal} fullWidth maxWidth="sm">
        <DialogTitle>Find Bank by IFSC Code</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Bank IFSC Code"
            variant="outlined"
            fullWidth
            value={bankSearchValue}
            onChange={(e) => setBankSearchValue(e.target.value)}
            className={styles.searchField}
          />
          <Button
            variant="contained"
            color="primary"
            className={styles.searchButton}
            fullWidth
            sx={{ marginTop: '20px' }}
            onClick={handleBank}
          >
            Search
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBankModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Section3;
