import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Skeleton,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styles from "./Cities.module.css"; // Import the module-level CSS
import LocationSearch from "./LocationSearch";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Cities = () => {
  const { state, district, city } = useParams();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState("");
  const [exampleItem, setExampleItem] = useState(null);

  useEffect(() => {
    const normalizedState = state.replace(/-/g, " ");
    const normalizedDistrict = district.replace(/-/g, " ");
    const normalizedCity = city.replace(/-/g, " ");

    // Fetch all data
    setLoadingData(true);
    axios
      .get(
        `https://www.backend.easytripss.com/public/pincode/allData?state=${normalizedState}&city=${normalizedDistrict}`
      )
      .then((response) => {
        setData(response.data.data);
        setLoadingData(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoadingData(false);
      });
  }, [state, district, city]);

  // Update example item when data is fetched
  useEffect(() => {
    const normalizedCity = city.replace(/-/g, " ");
    if (data.length > 0) {
      const filteredItem = data.find(
        (item) =>
          item.postOfficeName.toLowerCase() === normalizedCity.toLowerCase()
      );
      setExampleItem(filteredItem || null);
    }
  }, [data, city]);

  if (error) {
    return <Box className={styles.error}>{error}</Box>;
  }

  // Ensure the Helmet tags receive proper string values
  const pageTitle = exampleItem
    ? `${exampleItem.postOfficeName} Pincode Information | ${exampleItem?.district}, ${state}`
    : "Loading...";

  const pageDescription = exampleItem
    ? `Get detailed pincode information for ${exampleItem.postOfficeName}, located in ${exampleItem?.district}, ${state}. Learn about post offices, delivery status, and more.`
    : "Loading pincode details...";

  return (
    <>
      <NavBar />
      <Box className={styles.container}>
        <Helmet>
          <title>{pageTitle}</title>
          <link
            rel="canonical"
            href={`https://easytripss.com/pincode/${state}/${district}/${
              city || ""
            }`}
          />
          <meta name="description" content={pageDescription} />
          <meta
            name="keywords"
            content={`${
              exampleItem?.postOfficeName || "Loading"
            } pincode, ${exampleItem?.district} post office, ${state} postal code, ${
              exampleItem?.postOfficeName || "Loading"
            } ${exampleItem?.district} ${state} pin code`}
          />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://easytripss.com/${state}/${exampleItem?.district}/${
              city || ""
            }`}
          />
          <meta
            property="og:image"
            content="https://easytripss.com/og-image.jpg"
          />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "${
                  exampleItem?.postOfficeName || "Loading"
                } Pincode Information",
                "description": "Get detailed pincode information for ${
                  exampleItem?.postOfficeName || "Loading"
                }, located in ${exampleItem?.district}, ${state}. Learn about post offices, delivery status, and more.",
                "url": "https://easytripss.com/${state}/${exampleItem?.district}/${
              exampleItem?.postOfficeName || ""
            }",
                "mainEntity": {
                  "@type": "FAQPage",
                  "mainEntity": []
                }
              }
            `}
          </script>
        </Helmet>

        {/* Heading Section */}
        <div className={styles.heading_box}>
          <Typography variant="h1" gutterBottom className={styles.title}>
            {exampleItem?.postOfficeName || "Loading..."} in {exampleItem?.district}, {state}
          </Typography>
          {exampleItem ? (
            <div className={styles.info_title}>
              <Typography variant="h6" paragraph>
                The Pin Code of {exampleItem?.postOfficeName} is{" "}
                {exampleItem.pincode || "N/A"}. {exampleItem?.postOfficeName} is located in {exampleItem?.district}{" "}
                in {state}, India.
              </Typography>
              <Typography variant="body1" paragraph>
                {exampleItem.postOfficeName
                  ? `Get more details along with the Pin Code of ${exampleItem?.postOfficeName}. ${exampleItem?.postOfficeName} is located in ${exampleItem?.district} district in ${state}. ${
                      exampleItem.pincode || "N/A"
                    } is the pincode (Postal code) of ${exampleItem?.postOfficeName}.`
                  : ""}
              </Typography>
            </div>
          ) : (
            <Skeleton variant="text" width={400} height={40} />
          )}
        </div>

        {/* Table Section */}
        <div className={styles.container_box}>
          <div className={styles.table_box}>
            <div className={styles.tableWrapper}>
              <h2>
                The Pin Code of {exampleItem?.postOfficeName} is {exampleItem?.pincode || "N/A"}
              </h2>
              <p>
                {exampleItem?.postOfficeName}, a bustling neighborhood in {exampleItem?.district}, is one of the
                most well-known shopping and residential hubs in the city. Its
                central location, combined with a rich history and vibrant
                markets, makes it a significant part of {state}. For anyone
                living in, visiting, or sending parcels to this area, knowing
                the correct {exampleItem?.postOfficeName} Pincode is essential. The pincode of {exampleItem?.postOfficeName}{" "}
                plays an important role in ensuring efficient delivery of goods
                and services, including courier and postal deliveries.
              </p>
              <div className={styles.first_table}>
                {loadingData ? (
                  <Skeleton variant="rectangular" width="100%" height={200} />
                ) : (
                  <TableContainer
                    component={Paper}
                    className={styles.verticalTable}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Pincode</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.pincode || "N/A"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Post Office Name</strong>
                          </TableCell>
                          <TableCell>
                            {exampleItem?.postOfficeName || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Branch Type</strong>
                          </TableCell>
                          <TableCell>
                            {exampleItem?.branchType || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>District</strong>
                          </TableCell>
                          <TableCell>
                            {exampleItem?.district || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Division</strong>
                          </TableCell>
                          <TableCell>
                            {exampleItem?.division || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Region</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.region || "N/A"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Circle</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.circle || "N/A"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>State</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.state || "N/A"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Country</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.country || "N/A"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Block</strong>
                          </TableCell>
                          <TableCell>{exampleItem?.block || "N/A"}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <LocationSearch />
              </div>

              {/* All Related Pincodes Section */}
              <br />
              <h2>All Related Pincode</h2>
              <TableContainer component={Paper} className={styles.table}>
                {loadingData ? (
                  <Skeleton variant="rectangular" width="100%" height={200} />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Post Office Name</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Branch Type</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Pincode</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow key={item.pincode}>
                          <TableCell>{item.postOfficeName}</TableCell>
                          <TableCell>{item.branchType}</TableCell>
                          <TableCell>{item.pincode}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          </div>
        </div>
        <div className={styles.description_box}>
          <h2>Understanding the      {exampleItem?.postOfficeName } Pincode</h2>
          <p>
            The      {exampleItem?.postOfficeName } pincode is {exampleItem?.pincode}. This six-digit code is
            crucial for anyone residing in or doing business in this area, as it
            helps sort and deliver mail correctly. The pincode of      {exampleItem?.postOfficeName } is
            unique to this locality and is a part of the postal system managed
            by India Post. This system helps in identifying specific
            geographical regions to streamline the sorting process for all
            postal services.
          </p>
          <p>
            In India, the postal code system is also referred to as a Pincode
            (Postal Index Number), introduced in 1972. The first digit of the
            pincode represents the region, the second digit signifies the
            sub-region, and the third digit corresponds to the sorting district
            within the region. The last three digits represent the individual
            post office within that district. In the case of      {exampleItem?.postOfficeName }, the pincode{" "}
            {exampleItem?.pincode} indicates its location in {state}'s central
            area.
          </p>
          <h2>Significance of      {exampleItem?.postOfficeName } Pincode</h2>
          <p>
                 {exampleItem?.postOfficeName } is a busy and densely populated area, famous for its markets
            which attract thousands of visitors daily. The correct pincode of{" "}
                 {exampleItem?.postOfficeName } is crucial for not just residents but also business owners,
            retailers, and consumers who want to receive deliveries without any
            issues. From online shopping to sending personal mail, using the
            correct pincode      {exampleItem?.postOfficeName } ensures timely and accurate deliveries.
          </p>
          <p>
            Many times, individuals may find themselves looking for the pincode
                 {exampleItem?.postOfficeName } when filling out forms, completing address details for online
            purchases, or setting up business logistics. Incorrect pincodes can
            result in delayed or misplaced deliveries. Hence, knowing that the
            pincode      {exampleItem?.postOfficeName } {exampleItem?.district} is {exampleItem?.pincode} becomes very
            important.
          </p>
          <h2>Why the      {exampleItem?.postOfficeName } Pincode Matters</h2>
          <p>
            Being a key shopping district,      {exampleItem?.postOfficeName } sees a huge volume of online
            shopping, courier services, and business transactions. The
            {state} pincode      {exampleItem?.postOfficeName } assists postal and courier services in
            delivering items accurately and quickly. For businesses, especially
            in e-commerce, the right      {exampleItem?.postOfficeName } {state} pincode is vital to ensure
            packages and documents reach the correct destination.
          </p>
          <p>
            In addition to its shopping attractions,      {exampleItem?.postOfficeName } is also home to
            several residential areas, schools, and offices. This makes the
            pincode      {exampleItem?.postOfficeName } {exampleItem?.district} essential for a wide range of services,
            from ordering food to receiving official documents. It also plays a
            role in emergency services, enabling them to locate addresses
            quickly.
          </p>
          <h2>     {exampleItem?.postOfficeName }: More Than Just a Pincode</h2>
          <p>
            While the pincode of      {exampleItem?.postOfficeName } in {state} is a technical identifier,
            the area itself holds cultural and economic importance.      {exampleItem?.postOfficeName }
            is known for its markets, which offer a wide variety of products,
            including electronics, clothes, and jewelry. It also has historical
            significance, with several old establishments, temples, and mosques
            that are part of {state}'s rich heritage.
          </p>
          <p>
            In addition,      {exampleItem?.postOfficeName } has become a hotspot for visitors looking for
            both high-end products and affordable shopping experiences. With its
            proximity to Connaught Place and other central locations,      {exampleItem?.postOfficeName }{" "}
            remains a pivotal area in {exampleItem?.district}. The pincode of      {exampleItem?.postOfficeName } in
            {state}, {exampleItem?.pincode}, thus connects people to this
            vibrant neighborhood, both physically and culturally.
          </p>
          <h2>
            Zip Code of      {exampleItem?.postOfficeName } {state}
          </h2>
          <p>
            Although the term “zip code” is typically used in the United States,
            in India, the equivalent is the pincode. The zip code of      {exampleItem?.postOfficeName }
            {state}, therefore, is also {exampleItem?.pincode}. Visitors or new
            residents might sometimes search for the      {exampleItem?.postOfficeName } zip code, especially
            when coming from international destinations or filling out forms
            where "zip code" is mentioned instead of "pincode." It's essential
            to remember that both terms refer to the same code, which is{" "}
            {exampleItem?.pincode} in      {exampleItem?.postOfficeName }'s case.
          </p>
          <p>
            For those who might be unfamiliar, the      {exampleItem?.postOfficeName } zip code number is the
            same as its pincode. So, if you're ever looking for the zip code of{" "}
            {state}      {exampleItem?.postOfficeName } or someone asks for the zip code of      {exampleItem?.postOfficeName }, you can
            confidently provide them with {exampleItem?.pincode}.
          </p>
          <h2>Conclusion</h2>
          <p>
            In conclusion, whether you're a resident, a business owner, or just
            visiting the area, the      {exampleItem?.postOfficeName } pincode is crucial to ensure the
            smooth delivery of goods, services, and communication. The pincode
            of      {exampleItem?.postOfficeName }—{exampleItem?.pincode}—serves as a vital identifier for this central
            area of {state}. With its rich history, bustling markets, and growing
            significance in {state}'s commerce,      {exampleItem?.postOfficeName } remains one of the most
            important localities in the city.{" "}
          </p>
        </div>
      </Box>

      <Footer />
    </>
  );
};

export default Cities;
