// src/components/Footer.js

import React from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Link,
  Container,
  Divider,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <Box className={styles.footer} py={5}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4" className={styles.logo}>
              Easytripss
            </Typography>
            <Typography variant="body2" className={styles.tagline}>
              Your trusted travel partner.
            </Typography>
            <Box mt={2}>
              <Typography variant="h6" className={styles.followNow}>
                Follow Us
              </Typography>
              <Box className={styles.icons}>
                <IconButton
                  component={Link}
                  href="https://www.linkedin.com/company/easytripssofficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://youtube.com/@easytripss?si=wOfbAQEYRsUm2GIe"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                >
                  <YouTubeIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://x.com/easytripss"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://www.facebook.com/profile.php?id=61564921033103"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://www.instagram.com/easytripss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.exploreTitle}>
                  Company
                </Typography>
                <Box>
                  <Link href="/about-us" className={styles.footerLink}>
                    About Us
                  </Link>
                </Box>
                <Box>
                  <Link href="/contact-us" className={styles.footerLink}>
                    Contact Us
                  </Link>
                </Box>
                <Box>
                  <Link href="/disclaimer" className={styles.footerLink}>
                    Disclaimer
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.exploreTitle}>
                  Resources
                </Typography>
                <Box>
                  <Link href="/faq" className={styles.footerLink}>
                    FAQ
                  </Link>
                </Box>
                <Box>
                  <Link href="/terms-and-conditions" className={styles.footerLink}>
                    Terms of Conditions
                  </Link>
                </Box>
                <Box>
                  <Link href="/privacy-policy" className={styles.footerLink}>
                    Privacy Policy
                  </Link>
                </Box>
                <Box>
                  <Link href="/copyright-policy" className={styles.footerLink}>
                    Copyright Policy
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.exploreTitle}>
                  Explore
                </Typography>
                <Box>
                  <Link href="/bus" className={styles.footerLink}>
                    Bus Routes
                  </Link>
                </Box>
                <Box>
                  <Link href="/bus-stand" className={styles.footerLink}>
                    Bus Stand
                  </Link>
                </Box>
                <Box>
                  <Link href="/sitemap.xml" className={styles.footerLink}>
                    Sitemap
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.exploreTitle}>
                  Contact Us
                </Typography>
                {/* <Typography variant="body2" className={styles.contactInfo}>
                  123 Travel Lane,<br />
                  YourCity, YC 12345<br />
                  +1 (123) 456-7890
                </Typography> */}
                <Typography variant="body2" className={styles.contactInfo}>
                  Email: info@Easytripss.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <Divider style={{ backgroundColor: "#FFFFFF" }} />
      <p className={styles.copyRights}>Copyright © 2024 Easytripss</p>
    </Box>
  );
};

export default Footer;
