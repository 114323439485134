import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./FAQSection.module.css";

const FAQSection = ({ faqs }) => {
  return (
    <div className={styles.container}>
      <Typography variant="h4" gutterBottom className={styles.heading}>
        Frequently Asked Questions
      </Typography>
      {faqs?.additionalData?.faq.map((faq, index) => (
        <Accordion key={index} className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography className={styles.question}>{faq.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={styles.answer}>{faq.a}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQSection;
