import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Input,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet"; // For SEO meta tags and schema markup
import { Navigate, useNavigate, useParams } from "react-router-dom";
import NavBar from "../NavBar";
import Footer from "../Footer";
import styles from "./BankDetails.module.css"; // Import module-level CSS

const BankDetails = () => {
  const { ifscCode } = useParams();
  const [bankData, setBankData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchIfscCode, setSearchIfscCode] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchIfscCode(event.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.backend.easytripss.com/public/ifsc/bankData?ifscCode=${searchIfscCode}`
      );
      if (response.status) {
        navigate(`/bankdetails/${searchIfscCode}`);
      }
    } catch (error) {
      console.error("Error fetching bus data:", error);
      alert("Please Enter Correct IFSC Code");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const response = await axios.get(
          `https://www.backend.easytripss.com/public/ifsc/bankData?ifscCode=${ifscCode}`
        );
        setBankData(response.data.data);
      } catch (error) {
        console.error("Error fetching bank data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBankData();
  }, [ifscCode]);

  const getBranchCode = (code) => {
    // Extract the branch code from the IFSC code (last 4 characters)
    const branchCode = code.slice(-6);
    return branchCode;
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (!bankData) {
    return <Typography variant="h6">No data found.</Typography>;
  }

  return (
    <div className={styles.page}>
      <NavBar />
      <Container className={styles.container}>
        <Helmet>
          <title>Bank Details for {bankData?.BANK}</title>
          <meta
            name="description"
            content={`Details of ${bankData.BANK} branch including address, contact, and services.`}
          />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "BankOrCreditUnion",
              "name": "${bankData.BANK}",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${bankData.ADDRESS}",
                "addressLocality": "${bankData.CITY}",
                "addressRegion": "${bankData.STATE}",
                "postalCode": "${bankData.MICR}"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Service",
                "telephone": "${bankData.CONTACT || "Not Available"}"
              },
              "identifier": {
                "@type": "PropertyValue",
                "propertyID": "IFSC",
                "value": "${bankData.IFSC}"
              },
              "additionalType": "https://www.wikidata.org/wiki/Q33231"
            }
            `}
          </script>
        </Helmet>
        <div className={styles.heading_box}>
          <Typography variant="h4" gutterBottom className={styles.title}>
            Bank Details Of {bankData?.BANK}
          </Typography>
          <Typography variant="body1" paragraph className={styles.description}>
            The IFSC code is an essential part of India’s banking system,
            facilitating secure and efficient online financial transactions. The
            IFSC Code of {bankData?.BANK} {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()} is especially important for
            individuals or businesses who frequently conduct banking
            transactions in or around the {bankData?.CITY.toLowerCase()} area.
            Understanding the significance of the IFSC Code of {bankData?.BANK}{" "}
            Bank {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()}{" "}
            helps ensure that your electronic transactions, whether they are for
            transferring funds, paying bills, or other purposes, are completed
            accurately and securely.
          </Typography>
        </div>

        <Box className={styles.tableWrapper}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeadCell}>
                    <strong>Field</strong>
                  </TableCell>
                  <TableCell className={styles.tableHeadCell}>
                    <strong>Details</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(bankData).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <strong>{key.toUpperCase()}</strong>
                    </TableCell>
                    <TableCell>{value || "Not Available"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={styles.searchWrapper}>
            <Input
              placeholder="Enter IFSC Code"
              value={searchIfscCode}
              onChange={handleSearchChange}
              className={styles.input}
            />
            <Button onClick={handleSearch} className={styles.button}>
              Search
            </Button>
          </Box>
        </Box>
        <div>
          <h2>What is IFSC?</h2>
          <p>
            The term IFSC stands for Indian Financial System Code. It is an
            11-character alphanumeric code assigned to every bank branch in
            India that participates in online fund transfers through systems
            like NEFT (National Electronic Funds Transfer), RTGS (Real Time
            Gross Settlement), and IMPS (Immediate Payment Service). The IFSC
            Code of {bankData?.BANK} Bank branches, like all other banks in
            India, follows this standard format.
          </p>
          <p>
            An IFSC code is divided into two parts: the first four characters
            represent the bank, and the last six characters represent the
            specific branch. For example, the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()} is{" "}
            {ifscCode}, where "{bankData?.BANK}" stands for {bankData?.BANK} and
            "{getBranchCode(ifscCode)}" is the branch code of the{" "}
            {bankData?.CITY.toLowerCase()} branch.
          </p>
          <h2>
            Importance of the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()}
          </h2>
          <p>
            For anyone conducting financial transactions in the{" "}
            {bankData?.CITY.toLowerCase()} area, knowing the correct IFSC Code
            of {bankData?.BANK} {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()} is crucial. Whether you're
            transferring money to someone’s {bankData?.BANK}
            account, paying for a service, or processing salary transactions,
            the IFSC Code of {
              bankData?.BANK
            } {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()}{" "}
            ensures that the transaction reaches the correct branch without any
            delays or errors.
          </p>
          <p>
            Incorrect entry of the {bankData?.CITY.toLowerCase()} IFSC Code{" "}
            {bankData?.BANK} can lead to transactions failing, and this can
            cause unnecessary delays. It’s particularly important for businesses
            and individuals who conduct multiple transactions daily to keep the
            correct {bankData?.BANK} Bank IFSC Code
            {bankData?.CITY.toLowerCase()} on hand to avoid such issues.
          </p>
          <h2>Understanding the Structure of the IFSC Code</h2>
          <p>
            Let’s break down the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.CITY.toLowerCase()}—<strong>{ifscCode}</strong>
          </p>
          <p>
            <strong>{bankData?.BANK}:</strong> The first four letters indicate
            the bank name, which in this case is {bankData?.BANK}.
          </p>
          <p>
            <strong>{getBranchCode(ifscCode)}:</strong> The remaining six digits
            are unique to the {bankData?.CITY.toLowerCase()} branch in{" "}
            {bankData?.STATE.toLowerCase()}.
          </p>
          <p>
            This unique code not only identifies the branch but also helps banks
            route transactions to the appropriate branch. This process ensures
            secure and quick transfers, whether you're transferring funds
            through NEFT, RTGS, or IMPS.
          </p>
          <h2>
            How to Use the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()}{" "}
          </h2>
          <p>
            If you are transferring money to someone’s account at the{" "}
            {bankData?.CITY.toLowerCase()} branch of {bankData?.BANK} Bank,
            you'll need both the account number and the correct IFSC Code of{" "}
            {bankData?.BANK} {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()}. When you use internet banking or
            mobile banking for fund transfers, the system will ask for the
            beneficiary’s IFSC code. Entering the correct{" "}
            {bankData?.CITY.toLowerCase()} IFSC Code {bankData?.BANK} will
            ensure that the transaction is processed to the correct branch.
          </p>
          <p>
            Additionally, the IFSC Code of {bankData?.BANK} Bank{" "}
            {bankData?.CITY.toLowerCase()} can be used for a variety of online
            payment platforms and services, from paying bills to purchasing
            goods online. It is a key identifier that ensures that the
            transaction is securely routed to the appropriate account at the{" "}
            {bankData?.CITY.toLowerCase()} branch.
          </p>
          <h2>
            Where to Find the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()}
          </h2>
          <p>
            There are multiple ways to find the {bankData?.BANK} Bank IFSC Code{" "}
            {bankData?.CITY.toLowerCase()}:
          </p>
          <p>
            <strong>1. Bank Statement or Passbook:</strong> The IFSC code of the
            bank branch is usually printed on the top portion of a bank
            statement or on the front page of the passbook.
          </p>
          <p>
            <strong>2. Bank’s Website:</strong>
            {bankData?.BANK} Bank’s official website also provides the IFSC Code
            of {bankData?.BANK} {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()}. You can easily look it up by
            selecting the branch locator tool or IFSC code search option.
          </p>
          <p>
            <strong>3. Online Banking Platforms:</strong>
            Many websites and apps like ClearTax, BankBazaar, and Groww provide
            IFSC code search features. Searching for the IFSC Code of{" "}
            {bankData?.BANK} Bank {bankData?.CITY.toLowerCase()} on these
            platforms will yield the correct information, usually accompanied by
            other details like the branch address and contact information.
          </p>
          <p>
            <strong>4. Cheque Book:</strong>
            The IFSC code is also printed on the bottom part of the cheque leaf,
            usually just below the bank branch address.
          </p>
          <h2>Benefits of Using the Correct IFSC Code</h2>
          <p>
            Using the correct {bankData?.CITY.toLowerCase()} IFSC Code{" "}
            {bankData?.BANK} provides a range of benefits:
          </p>
          <p>
            <strong>Accuracy in Fund Transfers:</strong>The correct IFSC code
            ensures that the money being transferred reaches the intended branch
            and account, reducing the risk of errors or failed transactions.
          </p>
          <p>
            <strong>Fast Transactions:</strong>Systems like NEFT, RTGS, and IMPS
            depend on the IFSC code for routing the funds correctly. The right
            IFSC Code of
            {bankData?.BANK} Bank {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()} ensures that your transaction is
            processed quickly and without delays.
          </p>
          <p>
            <strong>Security: </strong> The use of IFSC codes in conjunction
            with account numbers and other banking details adds an extra layer
            of security to financial transactions. Knowing the IFSC Code of{" "}
            {bankData?.BANK} {bankData?.STATE.toLowerCase()}{" "}
            {bankData?.CITY.toLowerCase()} means that the transaction is not
            just accurate but also secure.
          </p>
          <h2>Conclusion</h2>
          <p>
            In conclusion, the IFSC Code of {bankData?.BANK}{" "}
            {bankData?.STATE.toLowerCase()} {bankData?.CITY.toLowerCase()} is an
            integral part of the financial ecosystem for individuals and
            businesses in the area. It enables quick, accurate, and secure
            electronic transfers. Whether you're paying a bill, transferring
            funds, or engaging in business transactions, using the correct{" "}
            {bankData?.BANK} Bank IFSC Code {bankData?.CITY.toLowerCase()}{" "}
            ensures that your money reaches its destination without any issues.
            Always double-check the IFSC Code of {bankData?.BANK} Bank{" "}
            {bankData?.CITY.toLowerCase()}—{ifscCode}—before initiating any
            online transactions to avoid any potential delays or errors.
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default BankDetails;
