import React, { useEffect } from "react";
import { Container, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styles from "./PrivacyPolicyPage.module.css";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { Helmet } from "react-helmet-async";

const PrivacyPolicyPage = () => {
  const theme = useTheme();

  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://easytripss.com/privacy-policy"
        />

        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>

        {/* Title and Meta Tags */}
        <title>EasyTripss - Privacy Policy | Protecting Your Privacy</title>
        <meta
          name="description"
          content="Read the EasyTripss Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is our priority."
        />
        <meta
          name="keywords"
          content="privacy policy, EasyTripss privacy, data protection, personal information, privacy practices"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EasyTripss - Privacy Policy | Protecting Your Privacy"
        />
        <meta
          property="og:description"
          content="Learn how EasyTripss handles your personal data. Our Privacy Policy outlines our commitment to safeguarding your privacy."
        />
        <meta
          property="og:url"
          content="https://easytripss.com/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://easytripss.com/privacy-policy-image.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="EasyTripss - Privacy Policy | Protecting Your Privacy"
        />
        <meta
          name="twitter:description"
          content="Our Privacy Policy explains how EasyTripss safeguards your personal information. We are committed to protecting your privacy."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/privacy-policy-image.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            potentialAction: {
              "@type": "SearchAction",
              target:
                "https://easytripss.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            logo: "https://easytripss.com/logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-800-555-1234",
              contactType: "Customer Service",
              areaServed: "US",
              availableLanguage: "English",
            },
            sameAs: [
              "https://www.facebook.com/EasyTripss",
              "https://twitter.com/EasyTripss",
              "https://www.instagram.com/EasyTripss",
            ],
          })}
        </script>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <NavBar />
      <Container className={styles.container}>
        <Typography variant="h1" className={styles.title}>
          Privacy Policy
        </Typography>
        <Paper className={styles.paper}>
          <Typography variant="h4" className={styles.sectionTitle}>
            Information We Collect
          </Typography>
          <Typography variant="body1" className={styles.text}>
            When you browse our website, we do not collect any personal or
            private information, such as names or addresses. However, if you
            choose to voluntarily provide us with such information, it may be
            used to fulfill your request for information.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We collect personal information that you provide to us, such as
            your name, email address, and phone number, when you sign up for our
            services, or contact us.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            Additionally, we automatically collect certain information when you
            visit our website, including your IP address, browser type, and
            browsing behavior.
          </Typography>
          <Typography variant="h4" className={styles.sectionTitle}>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We use your personal information to provide and improve our
            services, respond to your inquiries, and communicate updates or
            promotions. We may also use your information to analyze website
            traffic and user behavior to enhance your experience on
            Easytripss.com.
          </Typography>
          <Typography variant="h4" className={styles.sectionTitle}>
            Cookies
          </Typography>
          <Typography variant="body1" className={styles.text}>
            Easytripss.com uses cookies to enhance your browsing experience.
            Cookies help us understand your preferences and modify our website
            to suit your needs. You can choose to disable cookies through your
            browser settings, but this may affect the functionality of our
            website.
          </Typography>
          <Typography variant="h4" className={styles.sectionTitle}>
            Data Security
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We take appropriate measures to safeguard your personal information
            from unauthorized access, disclosure, or alteration.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            However, no method of data transmission over the internet is
            completely secure, and we cannot guarantee absolute security. So,
            please do not share any private information, such as bank account
            details or OTPs, as we will never ask for them.
          </Typography>
          <Typography variant="h4" className={styles.sectionTitle}>
            Changes to This Policy
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We may update our Privacy Policy from time to time. We will notify
            you of any significant changes by posting the updated policy on our
            website.
          </Typography>
          <Typography variant="h4" className={styles.sectionTitle}>
            Contact Us
          </Typography>
          <Typography variant="body1" className={styles.text}>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at info@easytripss.com.
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
