import React from 'react'

function CategoryPage() {
  return (
    <div>
      
    </div>
  )
}

export default CategoryPage
