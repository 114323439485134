import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // For redirection
import style from "./LocationSearch.module.css";

const LocationSearch = () => {
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const navigate = useNavigate(); // To redirect the user

  // Fetch all states on component mount
  useEffect(() => {
    axios
      .get("https://www.backend.easytripss.com/public/pincode/allIndianState")
      .then((response) => {
        setStates(response.data.data); // Array of states
      })
      .catch((error) => {
        console.error("Error fetching states", error);
      });
  }, []);

  // Fetch districts when a state is selected
  useEffect(() => {
    if (selectedState) {
      setLoadingDistricts(true);
      axios
        .get(
          `https://www.backend.easytripss.com/public/pincode/allDistrict?state=${selectedState}`
        )
        .then((response) => {
          setDistricts(response.data.data); // Array of districts
          setCities([]); // Reset cities when state changes
          setSelectedDistrict(""); // Reset district selection
          setSelectedCity(""); // Reset city selection
          setLoadingDistricts(false);
        })
        .catch((error) => {
          console.error("Error fetching districts", error);
          setLoadingDistricts(false);
        });
    } else {
      setDistricts([]); // Reset districts if no state selected
      setCities([]); // Reset cities
      setSelectedDistrict(""); // Clear district selection
      setSelectedCity(""); // Clear city selection
    }
  }, [selectedState]);

  // Fetch cities when a district is selected
  useEffect(() => {
    if (selectedState && selectedDistrict) {
      setLoadingCities(true);
      axios
        .get(
          `https://www.backend.easytripss.com/public/pincode/allData?state=${selectedState}&city=${selectedDistrict}`
        )
        .then((response) => {
          setCities(response.data.data.map((item) => item.postOfficeName)); // Extract city names from post office data
          setSelectedCity(""); // Reset city selection
          setLoadingCities(false);
        })
        .catch((error) => {
          console.error("Error fetching cities", error);
          setLoadingCities(false);
        });
    } else {
      setCities([]); // Reset cities if no district selected
      setSelectedCity(""); // Clear city selection
    }
  }, [selectedState, selectedDistrict]);

  // Filter function for search bars
  const filterOptions = (options, query) => {
    return options.filter((option) =>
      option.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Convert spaces to hyphens
  const formatForURL = (text) => {
    return text.trim().replace(/\s+/g, "-").toLowerCase();
  };

  // Handle search button click
  const handleSearch = () => {
    if (selectedState && selectedDistrict && selectedCity) {
      navigate(
        `/pincode/${formatForURL(selectedState)}/${formatForURL(
          selectedDistrict
        )}/${formatForURL(selectedCity)}`
      );
    } else if (selectedState && selectedDistrict) {
      navigate(
        `/pincode/${formatForURL(selectedState)}/${formatForURL(
          selectedDistrict
        )}`
      );
    } else if (selectedState) {
      navigate(`/pincode/${formatForURL(selectedState)}`);
    }
  };

  return (
    <Box className={style.main}>
      <Typography variant="h5" gutterBottom>
        Select Location
      </Typography>

      {/* State Search Bar */}
      <TextField
        label="State"
        fullWidth
        margin="normal"
        value={selectedState}
        onChange={(e) => setSelectedState(e.target.value)}
        select
        SelectProps={{
          native: false,
          renderValue: (value) => value || "Select a state",
          MenuProps: {
            PaperProps: {
              style: { maxHeight: 200 },
            },
          },
        }}
      >
        {states.map((state, index) => (
          <MenuItem key={index} value={state}>
            {state}
          </MenuItem>
        ))}
      </TextField>

      {/* District Search Bar */}
      {selectedState && (
        <TextField
          label="District"
          fullWidth
          margin="normal"
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.target.value)}
          select
          SelectProps={{
            native: false,
            renderValue: (value) => value || "Select a district",
            MenuProps: {
              PaperProps: {
                style: { maxHeight: 200 },
              },
            },
          }}
          disabled={loadingDistricts || !selectedState}
        >
          {loadingDistricts ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : (
            districts.map((district, index) => (
              <MenuItem key={index} value={district}>
                {district}
              </MenuItem>
            ))
          )}
        </TextField>
      )}

      {/* City Search Bar */}
      {selectedDistrict && (
        <TextField
          label="City / Post Office"
          fullWidth
          margin="normal"
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          select
          SelectProps={{
            native: false,
            renderValue: (value) => value || "Select a city",
            MenuProps: {
              PaperProps: {
                style: { maxHeight: 200 },
              },
            },
          }}
          disabled={loadingCities || !selectedDistrict}
        >
          {loadingCities ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : (
            cities.map((city, index) => (
              <MenuItem key={index} value={city}>
                {city}
              </MenuItem>
            ))
          )}
        </TextField>
      )}

      {/* Search Button */}
      <Button
        onClick={handleSearch}
        color="primary"
        variant="contained"
        style={{ marginTop: "20px" }}
        disabled={!selectedState}
      >
        Search
      </Button>
    </Box>
  );
};

export default LocationSearch;
