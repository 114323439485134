import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import styles from "./FAQSectionHomePage.module.css";

const FAQSectionHomePage = () => {
  const faqs = [
    {
      question: "How can I find nearby bus details in Delhi for my journey?",
      answer:
        "To find bus details for your journey in and around Delhi, simply enter your departure and arrival locations in our search bar. You'll get a comprehensive list of available Delhi buses with details like timings, bus type, and ticket prices. Whether you're traveling within Delhi or to nearby locations, EasyTripss has you covered.",
    },
    {
      question: "Can I book Delhi bus tickets directly on EasyTripss?",
      answer:
        "EasyTripss provides detailed information about bus routes and schedules in Delhi and surrounding areas. While ticket booking isn't available directly on our platform, we provide links to trusted third-party sites where you can easily book Delhi bus tickets.",
    },
    {
      question: "Are the Delhi bus schedules on EasyTripss accurate?",
      answer:
        "We regularly update our Delhi bus schedules to ensure accuracy. However, we recommend confirming timings with the bus operator, especially close to your travel date, to account for any last-minute changes in Delhi's busy traffic.",
    },
    {
      question: "Does EasyTripss offer real-time bus tracking in Delhi?",
      answer:
        "Yes, EasyTripss offers real-time bus tracking for selected operators in Delhi. Look for the live tracking option in the bus details section when viewing your route within Delhi or to nearby cities.",
    },
    {
      question: "What if I can't find my Delhi bus route on EasyTripss?",
      answer:
        "If you can't find a specific Delhi bus route, it might be a newly added or less frequented route. We're constantly expanding our database to include all Delhi and nearby bus routes. Please check back later or contact us for assistance.",
    },
    {
      question: "Does EasyTripss provide information on Delhi bus amenities?",
      answer:
        "Yes, we offer detailed information about amenities available on Delhi buses, such as Wi-Fi, charging points, and onboard restrooms. Check the bus details section to learn more about what each Delhi bus service offers.",
    },
    {
      question: "Can I compare different Delhi bus operators on EasyTripss?",
      answer:
        "Absolutely! EasyTripss allows you to compare various Delhi bus operators based on price, amenities, and user reviews. This way, you can choose the best Delhi bus service for your journey within or outside the city.",
    },
    {
      question: "Is there customer support available for Delhi bus queries?",
      answer:
        "Yes, EasyTripss offers dedicated customer support for any questions or concerns regarding Delhi bus services. Visit our contact page to reach out to us, and we'll be happy to assist you with your Delhi bus journey.",
    },
    {
      question: "How can I stay updated on new Delhi bus routes?",
      answer:
        "To stay informed about new bus routes in Delhi and nearby areas, regularly visit the EasyTripss website. We frequently update our listings to include the latest Delhi bus routes, ensuring you have the most accurate and up-to-date information.",
    },
    {
      question: "Are there budget-friendly bus options in Delhi?",
      answer:
        "Yes, EasyTripss helps you find budget-friendly bus options across Delhi. Whether you're looking for a local Delhi bus or an intercity bus service, we provide a range of choices to fit your budget and travel needs.",
    },
  ];

  // Schema Markup
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map((faq) => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer,
      },
    })),
  };

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h4" gutterBottom className={styles.heading}>
          Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <Accordion key={index} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography className={styles.question}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={styles.answer}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default FAQSectionHomePage;
