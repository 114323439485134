import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import NavBar from "../NavBar";
import Section1 from "../sections/HomePageSection/Section1";
import styles from "./HomePage.module.css";
import Section2 from "../sections/HomePageSection/Section2";
import Footer from "../Footer";
import Section3 from "../sections/HomePageSection/Section3";
import FAQSectionHomePage from "../sections/HomePageSection/FAQSectionHomePage";

const HomePage = () => {
  const faqs = [
    {
      question: "How can I find bus details for my journey?",
      answer:
        "To find bus details, simply enter your departure and arrival locations in the search bar. You'll get a list of available buses with information like timings, bus type, and ticket prices.",
    },
    {
      question: "Can I book bus tickets directly on EasyTripss?",
      answer:
        "Currently, EasyTripss provides detailed information about bus routes and schedules. While you can't book tickets directly through our platform, we provide links to trusted third-party booking sites.",
    },
    {
      question: "Are the bus schedules on EasyTripss accurate?",
      answer:
        "We update our schedules regularly to ensure accuracy. However, it's always a good idea to confirm timings with the bus operator, especially close to your travel date.",
    },
    {
      question: "Does EasyTripss offer real-time bus tracking?",
      answer:
        "Yes, EasyTripss offers real-time bus tracking for selected operators. Look for the tracking option in the bus details section when viewing your route.",
    },
    {
      question: "What if I can't find my bus route on EasyTripss?",
      answer:
        "We're constantly adding new routes and operators to our database. If you can't find a specific route, please check back later or contact us for assistance.",
    },
    {
      question: "Does EasyTripss provide information on bus amenities?",
      answer:
        "Yes, we provide details about bus amenities such as Wi-Fi, charging points, and onboard restrooms in the bus details section for each route.",
    },
    {
      question: "Can I compare different bus operators on EasyTripss?",
      answer:
        "Absolutely! EasyTripss allows you to compare multiple bus operators based on price, amenities, and user reviews, so you can choose the best option for your journey.",
    },
    {
      question: "Is there customer support available if I have questions?",
      answer:
        "Yes, EasyTripss offers customer support through our contact page. You can reach out to us with any questions or concerns, and we'll be happy to assist you.",
    },
    // Add more FAQs as needed
  ];
  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);
  
  return (
    <div className={styles.main}>
<Helmet>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        data-ad-client="ca-pub-1396556165266132"
        crossorigin="anonymous"
      ></script>
  {/* Google Tag Manager Script */}
  <script>
    {`
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
    `}
  </script>

  {/* Title and Meta Tags */}
  <title>Easytripss - Delhi Bus Routes & Bus Stand Information</title>
  <meta
    name="description"
    content="Find comprehensive information on bus routes, bus stands, and stops across Delhi with Easytripss. Get real-time updates and navigate your travel easily."
  />
  <meta
    name="keywords"
    content="Delhi bus routes, bus stands in Delhi, Delhi bus stops, bus information Delhi, find bus routes Delhi, local bus routes Delhi, Delhi public transport, bus schedule Delhi, Delhi bus tracker, nearby bus stands"
  />
  <link rel="canonical" href="https://easytripss.com/" />

  {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:title"
    content="Easytripss - Delhi Bus Routes & Bus Stand Information"
  />
  <meta
    property="og:description"
    content="Explore detailed bus routes, bus stands, and stops across Delhi with Easytripss. Get accurate and real-time information for a smooth travel experience."
  />
  <meta property="og:url" content="https://easytripss.com/" />
  <meta
    property="og:image"
    content="https://easytripss.com/your-image-url.jpg"
  />
  <meta property="og:site_name" content="Easytripss" />

  {/* Twitter */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Easytripss - Delhi Bus Routes & Bus Stand Information"
  />
  <meta
    name="twitter:description"
    content="Find all the information you need about Delhi bus routes, bus stands, and stops with Easytripss. Navigate your journey with ease."
  />
  <meta
    name="twitter:image"
    content="https://res.cloudinary.com/dch7mrf5x/image/upload/v1724673697/BusImage/g2l3gpcwciyfnikyuud5.png"
  />
  <meta name="twitter:site" content="@Easytripss" />

  {/* Schema.org JSON-LD */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Easytripss",
      url: "https://easytripss.com/",
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://easytripss.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    })}
  </script>

  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Easytripss",
      url: "https://easytripss.com/",
      logo: "https://res.cloudinary.com/dch7mrf5x/image/upload/v1724673697/BusImage/g2l3gpcwciyfnikyuud5.png",
      sameAs: [
        "https://www.facebook.com/Easytripss",
        "https://twitter.com/Easytripss",
        "https://www.instagram.com/Easytripss",
      ],
    })}
  </script>
</Helmet>
<noscript>
  <iframe
    src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
    height="0"
    width="0"
    style={{ display: "none", visibility: "hidden" }}
  ></iframe>
</noscript>
      <h1 hidden>
      Easytripss - Delhi Bus Routes & Bus Stand Information
      </h1>
      <NavBar />
      <Section1 />
      <Section3 />
      <Section2 />
      <FAQSectionHomePage />
      <br/>
      <Footer />
    </div>
  );
};

export default HomePage;
