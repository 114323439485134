import React, { useEffect } from "react";
import { Container, Typography, Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import NavBar from "../NavBar";
import Footer from "../Footer";
import styles from "./CopyrightPolicy.module.css";

const CopyrightPolicy = () => {
  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossOrigin="anonymous"
        ></script>
        {/* Title and Meta Tags */}
        <title>EasyTripss - Copyright Policy | Protecting Your Rights</title>
        <meta
          name="description"
          content="Read the EasyTripss Copyright Policy to understand how we handle copyrighted material and what to do if you believe your work has been infringed upon."
        />
        <meta
          name="keywords"
          content="copyright policy, EasyTripss copyright, intellectual property, copyright infringement"
        />
        <link rel="canonical" href="https://easytripss.com/copyright-policy" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EasyTripss - Copyright Policy | Protecting Your Rights"
        />
        <meta
          property="og:description"
          content="Learn about EasyTripss's Copyright Policy and what steps to take if you believe your copyrighted work has been infringed."
        />
        <meta property="og:url" content="https://easytripss.com/copyright-policy" />
        <meta
          property="og:image"
          content="https://easytripss.com/copyright-policy-image.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="EasyTripss - Copyright Policy | Protecting Your Rights"
        />
        <meta
          name="twitter:description"
          content="Our Copyright Policy explains how EasyTripss manages copyrighted content and provides guidance if you believe your work has been infringed."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/copyright-policy-image.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />
      </Helmet>
      <NavBar />
      <Container className={styles.container}>
        <Typography variant="h1" className={styles.heading}>
          Copyright Policy
        </Typography>
        <Paper className={styles.paper}>
          <Typography variant="h2" className={styles.sectionTitle}>
            Introduction
          </Typography>
          <Typography className={styles.paragraph}>
            Welcome to our Copyright Policy page. This policy outlines how we handle copyrighted material and what you should do if you believe your work has been infringed upon.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            Copyright Infringement
          </Typography>
          <Typography className={styles.paragraph}>
            If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please provide us with a written notice that includes the following information:
          </Typography>
          <ul className={styles.list}>
            <li>Your name, address, telephone number, and email address;</li>
            <li>A description of the copyrighted work that you claim has been infringed;</li>
            <li>A description of where the alleged infringing material is located on the site;</li>
            <li>An electronic or physical signature of the person authorized to act on behalf of the copyright owner.</li>
          </ul>
          <Typography variant="h2" className={styles.sectionTitle}>
            Contact Us
          </Typography>
          <Typography className={styles.paragraph}>
            If you have any questions about our Copyright Policy, please contact us at <a href="mailto:copyright@Easytripss.com">copyright@Easytripss.com</a>.
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default CopyrightPolicy;
