import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import style from "./BusRoute.module.css"
import BusRouteSection   from "./BusRouteSection"

function BusRoute() {
  return (
    <div className={style.main}>
      <NavBar />
      <BusRouteSection />
      <Footer />
    </div>
  );
}

export default BusRoute;
