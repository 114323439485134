import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Components/Pages/HomePage';
import FilterBusPage from './Components/Pages/FilterBusPage';
import BusBlogPage from './Components/Pages/FromToPage';
import CategoryPage from './Components/Pages/CategoryPage';
import AboutUsPage from './Components/Pages/AboutUsPage';
import ContactUsPage from './Components/Pages/ContactUsPage';
import PrivacyPolicyPage from './Components/Pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './Components/Pages/TermsAndConditionsPage';
import FaqPage from './Components/Pages/FaqPage';
import FromToPage from './Components/Pages/FromToPage';
import BusRoute from './Components/Pages/BusRoute';
import AllBusStand from './Components/Pages/AllBusStand';
import BusStand from './Components/Pages/BusStand';
import CopyrightPolicy from './Components/Pages/CopyrightPolicy';
import DisclaimerPage from './Components/Pages/DisclaimerPage';
import Pincode from './Components/Pages/Pincode';
import Cities from './Components/Pages/Cities';
import Districts from './Components/Pages/Districts';
import States from './Components/Pages/States';
import BankDetails from './Components/Pages/BankDetails';




const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/:title" element={<FilterBusPage />} />
        <Route path="/bus/:from-to" element={<FromToPage />} />
        <Route path="/bus-stand/:selectedCity" element={<AllBusStand />} />
        <Route path="/bus-stand" element={<BusStand />} />
        <Route path="/category" element={<CategoryPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/bus" element={<BusRoute />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/copyright-policy" element={<CopyrightPolicy />} />
        <Route path="/disclaimer" element={<DisclaimerPage/>} />
        <Route path="/pincode" element={<Pincode/>} />
        <Route path="/pincode/:state/:district/:city" element={<Cities/>} />
        <Route path="/pincode/:state/:district" element={<Districts/>} />
        <Route path="/pincode/:state" element={<States/>} />
        <Route path="/bankdetails/:ifscCode" element={<BankDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
