import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styles from "./States.module.css"; // Import the module-level CSS
import LocationSearch from "./LocationSearch";
import NavBar from "../NavBar";
import Footer from "../Footer";

const States = () => {
  const { state, district } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Normalize parameters
    const normalizedState = state.replace(/-/g, " ");
    const normalizedDistrict = district ? district.replace(/-/g, " ") : null;

    // Fetch district data
    axios
      .get(
        ` https://www.backend.easytripss.com/public/pincode/allDistrict?state=${normalizedState}`
      )
      .then((response) => {
        setData(response.data.data); // Set district names
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, [state, district]); // Added district dependency

  // Convert spaces to hyphens
  const formatForURL = (text) => {
    return text.trim().replace(/\s+/g, "-");
  };

  if (loading) {
    return (
      <Box className={styles.loading}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box className={styles.error}>{error}</Box>;
  }

  return (
    <>
      <NavBar />
      <Box className={styles.container}>
        <Helmet>
          <title>
            {district
              ? `${district} District Pincode Information`
              : `Pincode Information`}{" "}
            | {state}
          </title>
          <link
            rel="canonical"
            href={`https://easytripss.com/pincode/${state}/${district || ""}`}
          />
          <meta
            name="description"
            content={`Get detailed pincode information for ${
              district || "this district"
            }, located in ${state}. Learn about post offices, delivery status, and more.`}
          />
          <meta
            name="keywords"
            content={`${district || "district"} pincode, ${
              district || "district"
            } post office, ${state} postal code`}
          />
          <meta
            property="og:title"
            content={`${
              district
                ? `${district} Pincode Information`
                : "Pincode Information"
            } | ${state}`}
          />
          <meta
            property="og:description"
            content={`Get detailed pincode information for ${
              district || "this district"
            }, located in ${state}. Learn about post offices, delivery status, and more.`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://easytripss.com/pincode/${state}/${
              district || ""
            }`}
          />
          <meta
            property="og:image"
            content="https://easytripss.com/og-image.jpg"
          />
        </Helmet>
        <div className={styles.heading_box}>
          <Typography variant="h1" gutterBottom className={styles.title}>
            {district ? `${district} District` : "District"} in {state}
          </Typography>
          <Typography variant="h6" paragraph>
            {`The Pin Code of ${
              district || "this district"
            } is not available for this district in the current data. ${
              district ? `${district} is located in ` : ""
            }${state}, India.`}
          </Typography>
          <Typography variant="body1" paragraph>
            {`Get more details along with the Pin Code of ${
              district || "this district"
            }. ${district ? `${district} is located in ` : ""}${state}.`}
          </Typography>
        </div>
        <div className={styles.container_box}>
          <LocationSearch />
          <TableContainer component={Paper}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>District</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data.map((districtName, index) => (
                    <TableRow
                      key={index}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        (window.location.href = `/pincode/${state}/${formatForURL(districtName)}`)
                      }
                    >
                      <TableCell>{districtName}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default States;
