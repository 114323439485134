import React, { useEffect, useState } from "react";
import styles from "./BusRouteSection.module.css";
import { Grid, CircularProgress, TextField, Button } from "@mui/material";
import axios from "axios";
import { Helmet } from "react-helmet";
import BusCard from "../BusCard";

function BusRouteSection() {
  const [busData, setBusData] = useState([]);
  const [filteredBusData, setFilteredBusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCount, setVisibleCount] = useState(8);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    axios
      .get(` https://www.backend.easytripss.com/public/bus/getAllBus`)
      .then((response) => {
        if (response.data.status) {
          setBusData(response.data.data || []);
          setFilteredBusData(response.data.data || []);
        } else {
          setError("Failed to load bus data");
        }
      })
      .catch((error) => {
        console.error("Error fetching bus data:", error);
        setError("Error fetching bus data");
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const filtered = busData.filter((bus) =>
      bus?.busNumber?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBusData(filtered);
  }, [searchQuery, busData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
    setShowMore(filteredBusData.length > visibleCount + 8);
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => Math.max(prevCount - 8, 8));
    setShowMore(true);
  };

  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <Helmet>
            <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        data-ad-client="ca-pub-1396556165266132"
        crossorigin="anonymous"
      ></script>
        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>
        <title>
          Find Related Buses Near You - Search by Bus Number | Easytripss
        </title>
        <meta
          name="description"
          content="Discover and search for related buses near you with ease. Use our bus number search feature to find nearby bus services and plan your commute efficiently. Explore a wide range of bus routes and services available in your area."
        />
        <meta
          name="keywords"
          content="Related buses near you,Bus number search,Nearby bus services,Find buses near me,Bus routes in Delhi,Local bus schedules,Bus services in Delhi,Search buses by number,Plan your commute,Bus stops near me,"
        />
        <meta name="author" content="Easy Tripss" />
        <meta name="robots" content="index, follow" />

        <link rel="canonical" href={`https://easytripss.com/bus`} />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Find Related Buses Near You - Search by Bus Number | Easytripss"
        />
        <meta
          property="og:description"
          content="Discover and search for related buses near you with ease. Use our bus number search feature to find nearby bus services and plan your commute efficiently. Explore a wide range of bus routes and services available in your area."
        />
        <meta property="og:image" content={busData?.busImage} />
        <meta
          property="og:url"
          content="https://easytripss.com/218-dtc-bus-route"
        />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Find Related Buses Near You - Search by Bus Number | Easytripss"
        />
        <meta
          name="twitter:description"
          content="Discover and search for related buses near you with ease. Use our bus number search feature to find nearby bus services and plan your commute efficiently. Explore a wide range of bus routes and services available in your area."
        />
        <meta name="twitter:image" content={busData?.busImage} />

        {/* Schema Markup for BlogPosting */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://easytripss.com/bus",
            },
            headline:
              "Find Related Buses Near You - Search by Bus Number | Easytripss",
            image: busData?.busImage,
            author: {
              "@type": "Organization",
              name: "Easy Tripss",
            },
            publisher: {
              "@type": "Organization",
              name: "Easy Tripss",
              logo: {
                "@type": "ImageObject",
                url: "https://easytripss.com/logo.png", // Replace with your logo URL
              },
            },
          })}
        </script>
      </Helmet>
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <h1 className={styles.title}>
        Find Related Buses Near You - Search by Bus Number | Easytripss
      </h1>
      <div className={styles.searchContainer}>
        <TextField
          label="Search by Bus Number"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
        />
      </div>
      <div className={styles.busCardList}>
        <Grid container spacing={3} className={styles.cardContainer}>
          {filteredBusData.slice(0, visibleCount).length > 0 ? (
            filteredBusData.slice(0, visibleCount).map((bus) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={bus?._id}>
                <BusCard
                  busNumber={bus?.busNumber}
                  busTitle={bus?.busTittle}
                  busImage={bus?.busImage}
                  description={bus?.busContent}
                  createdAt={bus?.createdAt}
                  updatedAt={bus?.updatedAt}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <p>No buses found</p>
            </Grid>
          )}
        </Grid>
        <div className={styles.toggleContainer}>
          {filteredBusData.length > visibleCount && showMore && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowMore}
            >
              Show More
            </Button>
          )}
          {visibleCount > 8 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleShowLess}
            >
              Show Less
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BusRouteSection;
