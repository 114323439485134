import React, { useEffect, useState } from "react";
import styles from "./Section2.module.css";
import { Grid, CircularProgress, TextField, Button } from "@mui/material";
import BusCard from "../../BusCard";
import axios from "axios";
import { Helmet } from "react-helmet";

function Section2() {
  const [busData, setBusData] = useState([]);
  const [filteredBusData, setFilteredBusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCount, setVisibleCount] = useState(8);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    axios
      .get(` https://www.backend.easytripss.com/public/bus/getAllBus`)
      .then((response) => {
        if (response.data.status) {
          setBusData(response.data.data || []);
          setFilteredBusData(response.data.data || []);
        } else {
          setError("Failed to load bus data");
        }
      })
      .catch((error) => {
        console.error("Error fetching bus data:", error);
        setError("Error fetching bus data");
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const filtered = busData.filter((bus) =>
      bus?.busNumber?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBusData(filtered);
  }, [searchQuery, busData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
    setShowMore(filteredBusData.length > visibleCount + 8);
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => Math.max(prevCount - 8, 8));
    setShowMore(true);
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>
        Find Related Buses Near You - Search by Bus Number | Easytripss
      </h2>
      <div className={styles.searchContainer}>
        <TextField
          label="Search by Bus Number"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
        />
      </div>
      <div className={styles.busCardList}>
        <Grid container spacing={3} className={styles.cardContainer}>
          {filteredBusData.slice(0, visibleCount).length > 0 ? (
            filteredBusData.slice(0, visibleCount).map((bus) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={bus?._id}>
                <BusCard
                  busNumber={bus?.busNumber}
                  busTitle={bus?.busTittle}
                  busImage={bus?.busImage}
                  description={bus?.busContent}
                  createdAt={bus?.createdAt}
                  updatedAt={bus?.updatedAt}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <p>No buses found</p>
            </Grid>
          )}
        </Grid>
        <div className={styles.toggleContainer}>
          {filteredBusData.length > visibleCount && showMore && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowMore}
            >
              Show More
            </Button>
          )}
          {visibleCount > 8 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleShowLess}
            >
              Show Less
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Section2;
