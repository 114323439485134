import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Popper,
  Paper,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./NavBar.module.css";

const NavBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [openFromSuggestions, setOpenFromSuggestions] = useState(false);
  const [openToSuggestions, setOpenToSuggestions] = useState(false);
  const navigate = useNavigate();

  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const popperFromRef = useRef(null);
  const popperToRef = useRef(null);

  useEffect(() => {
    const fetchCityOptions = async () => {
      try {
        const response = await axios.get(
          ` https://www.backend.easytripss.com/public/bus/allstops`
        );
        setCityOptions(response.data.data);
      } catch (error) {
        console.error("Error fetching city options:", error);
      }
    };
    fetchCityOptions();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleFromChange = (event) => {
    setFromValue(event.target.value);
    setOpenFromSuggestions(true);
    setOpenToSuggestions(false);
  };

  const handleToChange = (event) => {
    setToValue(event.target.value);
    setOpenToSuggestions(true);
    setOpenFromSuggestions(false);
  };

  const handleSuggestionClick = (value, type) => {
    if (type === "from") {
      setFromValue(value);
      setOpenFromSuggestions(false);
    } else {
      setToValue(value);
      setOpenToSuggestions(false);
    }
  };

  // Function to format URL with special characters
  const formatForUrl = (value) => {
    return value
      .replace(/\s+/g, "-") 
      .replace(/[^\w\-()]/g, ""); // Remove all characters except alphanumeric, hyphens, and parentheses
  };

  const handleSearch = async () => {
    try {
      const formattedFrom = formatForUrl(fromValue);
      const formattedTo = formatForUrl(toValue);
      const response = await axios.get(
        ` https://www.backend.easytripss.com/public/bus/getFromTo?from=${encodeURIComponent(fromValue)}&to=${encodeURIComponent(toValue)}`
      );
      if (response.status) {
        navigate(`/bus/${formattedFrom}-to-${formattedTo}`);
      }
    } catch (error) {
      alert("No bus found for this route.");
    }
  };

  const filterCities = (input) => {
    return cityOptions.filter((city) =>
      city.toLowerCase().includes(input.toLowerCase())
    );
  };

  const filteredFromOptions = filterCities(fromValue);
  const filteredToOptions = filterCities(toValue);

  return (
    <AppBar position="static" color="transparent" elevation={0} className={styles.navbar}>
      <Toolbar className={styles.toolbar}>
        <Box className={styles.logo}>
          <Link to={"/"} className={styles.link}>
            <Typography variant="h4" component="div" className={styles.logoText}>
              Easy<span className={styles.highlight}>Tripss</span>
            </Typography>
          </Link>
        </Box>
        <Box className={`${styles.searchBar} ${mobileOpen ? styles.hidden : ""}`}>
          <InputBase
            placeholder="From"
            value={fromValue}
            className={styles.searchInput}
            onChange={handleFromChange}
            ref={fromInputRef}
            startAdornment={<LocationOnIcon className={styles.icon} />}
          />
          <InputBase
            placeholder="To"
            value={toValue}
            className={styles.searchInput}
            onChange={handleToChange}
            ref={toInputRef}
            startAdornment={<LocationOnIcon className={styles.icon} />}
          />
          <IconButton className={styles.searchButton} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box className={`${styles.navLinks} ${mobileOpen ? styles.hidden : ""}`}>
          <Link to="/" className={styles.navLink}>Home</Link>
          <Link to="/about-us" className={styles.navLink}>About Us</Link>
          <Link to="/contact-us" className={styles.navLink}>Contact Us</Link>
        </Box>
        <Box className={styles.navActions}>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle} className={styles.menuButton}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle} classes={{ paper: styles.drawerPaper }}>
        <Box className={styles.drawerContent}>
          <IconButton className={styles.closeButton} onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
          <Box className={styles.searchBarDrawer}>
            <InputBase
              placeholder="From"
              value={fromValue}
              className={styles.searchInput}
              onChange={handleFromChange}
              ref={fromInputRef}
              startAdornment={<LocationOnIcon />}
            />
            <InputBase
              placeholder="To"
              value={toValue}
              className={styles.searchInput}
              onChange={handleToChange}
              ref={toInputRef}
              startAdornment={<LocationOnIcon />}
            />
            <IconButton className={styles.searchButton} onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Box>
          <List>
            <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/about-us" onClick={handleDrawerToggle}>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={Link} to="/contact-us" onClick={handleDrawerToggle}>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Popper for From suggestions */}
      <Popper
        open={openFromSuggestions && Boolean(fromValue)}
        anchorEl={fromInputRef.current}
        placement="bottom-start"
        className={styles.popper}
        ref={popperFromRef}
      >
        <Paper className={styles.popperContent}>
          {filteredFromOptions.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleSuggestionClick(option, "from")}
            >
              {option}
            </MenuItem>
          ))}
        </Paper>
      </Popper>

      {/* Popper for To suggestions */}
      <Popper
        open={openToSuggestions && Boolean(toValue)}
        anchorEl={toInputRef.current}
        placement="bottom-start"
        className={styles.popper}
        ref={popperToRef}
      >
        <Paper className={styles.popperContent}>
          {filteredToOptions.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleSuggestionClick(option, "to")}
            >
              {option}
            </MenuItem>
          ))}
        </Paper>
      </Popper>
    </AppBar>
  );
};

export default NavBar;
