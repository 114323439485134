import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  ButtonGroup,
  Grid,
  Button,
} from "@mui/material";
import styles from "./FilterBusPage.module.css";
import Footer from "../Footer";
import NavBar from "../NavBar";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import BusCard from "../BusCard";
import Breadcrumbs from "../Breadcrumbs";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FilterBusPage = () => {
  const { title } = useParams();
  const [busData, setBusData] = useState({});
  const [allBlogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = "https://easytripss.com";
  const shareUrl = `${baseUrl}/${title}`;
  const [activeSection, setActiveSection] = useState("");
  const [searchBus, setSearchBus] = useState("");
  const [showSearchBus, setShowSearchBus] = useState(false);
  const [visibleCards, setVisibleCards] = useState(8); // State to control the number of visible cards

  const showMore = () => {
    setVisibleCards((prev) => prev + 8); // Show 10 more cards
  };

  const showLess = () => {
    setVisibleCards(8); // Show only the initial 10 cards
  };

  useEffect(() => {
    axios
      .get(` https://www.backend.easytripss.com/public/bus/getAllBus`)
      .then((response) => {
        if (response.data.status) {
          const allBusData = response.data.data;
          setBlogs(response.data.data);
          // Find the bus with the matching title
          const filteredBus = allBusData.find(
            (item) =>
              item.busTittle
                ?.toLowerCase()
                ?.replace(/[^a-z0-9\s]/g, "")
                ?.trim()
                ?.replace(/\s+/g, "-") === title
          );

          setBusData(filteredBus);
        }
      })
      .catch((error) => console.error("Error fetching bus data:", error))
      .finally(() => setLoading(false));
  }, [title]); // Re-run the effect when the title changes

  const sectionRefs = {
    section1: "section1",
    section2: "section2",
    section3: "section3",
    section4: "section4",
    landMark: "landMark",
    freqOfBus: "freqOfBus",
    totalNumOfStops: "totalNumOfStops",
    nearByAttractions: "nearByAttractions",
    safetyMeasures: "safetyMeasures",
    dailyPassengersTips: "dailyPassengersTips",
    specialNotes: "specialNotes",
    subSection1A: "subSection1A",
    subSection1B: "subSection1B",
    subSection2A: "subSection2A",
    subSection2B: "subSection2B",
    subSection2C: "subSection2C",
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.pageYOffset;
      const sections = Object.keys(sectionRefs);
      let currentSection = "";

      sections.forEach((section) => {
        const element = document.getElementById(sectionRefs[section]);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 1 && rect.bottom >= 0) {
            currentSection = section;
          }
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const getActiveStyle = (section) => {
    return activeSection === section
      ? { color: "blue", fontWeight: "bold" }
      : {};
  };

  const handleShareClick = (platform) => {
    const urls = {
      twitter: `https://twitter.com/share?url=${shareUrl}&text=Check out this bus on Easytripss!`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      whatsapp: `https://api.whatsapp.com/send?text=Check out this bus on Easytripss! ${shareUrl}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=Check out this bus on Easytripss!`,
      instagram: `https://www.instagram.com/?url=${shareUrl}`, // Instagram sharing works differently, usually requires an image or use stories
      pinterest: `https://pinterest.com/pin/create/button/?url=${shareUrl}&description=Check out this bus on Easytripss!`,
    };
    window.open(urls[platform], "_blank");
  };

  const formatBlogDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleSearchBus = (e) => {
    setSearchBus(e.target.value);
    if (e.target.value.length > 0) setShowSearchBus(true);
    if (e.target.value.length === 0) setShowSearchBus(false);
  };
  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, "") // Remove special characters
      .trim() // Trim leading/trailing whitespace
      .replace(/\s+/g, "-"); // Replace spaces with hyphens
  };

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);


  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const formatForUrl = (value) => {
    return value
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-()]/g, ""); // Remove all characters except alphanumeric, hyphens, and parentheses
  };

  return (
    <div className={styles.main}>
      <Helmet>
          {/* Google AdSense Script */}
          <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          data-ad-client="ca-pub-1396556165266132"
          crossorigin="anonymous"
        ></script>
        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>
        <title>{busData?.meta_title}</title>
        <meta name="description" content={busData?.metaDescription} />
        <meta name="keywords" content={busData?.metaDescription} />
        <meta name="author" content="Easy Tripss" />
        <meta name="robots" content="index, follow" />

        <link rel="canonical" href={`https://easytripss.com/${title}`} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={busData?.meta_title} />
        <meta property="og:description" content={busData?.metaDescription} />
        <meta property="og:image" content={busData?.busImage} />
        <meta
          property="og:url"
          content="https://easytripss.com/218-dtc-bus-route"
        />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={busData?.meta_title} />
        <meta name="twitter:description" content={busData?.metaDescription} />
        <meta name="twitter:image" content={busData?.busImage} />

        {/* Schema Markup for BlogPosting */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://easytripss.com/${title}`,
            },
            headline: busData?.busTittle,
            image: busData?.busImage,
            author: {
              "@type": "Organization",
              name: "Easy Tripss",
            },
            publisher: {
              "@type": "Organization",
              name: "Easy Tripss",
              logo: {
                "@type": "ImageObject",
                url: "https://easytripss.com/logo.png", // Replace with your logo URL
              },
            },
            faqSchema: {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: busData?.section3?.faq?.map((faqItem) => ({
                "@type": "Question",
                name: faqItem?.que,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: faqItem?.ans,
                },
              })),
            },
            datePublished: busData?.createdAt,
            dateModified: busData?.updatedAt,
            description: busData?.busContent?.replace(/<[^>]+>/g, ""),
            articleBody: busData?.busContent?.replace(/<[^>]+>/g, ""),
          })}
        </script>
      </Helmet>
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <NavBar />
      <Breadcrumbs />
      <Box className={styles.titleBox}>
         {/* Google AdSense Ad Placement */}
                  <Box className={styles.adsenseBox}>
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-1396556165266132"
              data-ad-slot="4527423172"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </Box>
        <div className={styles.container_box}>
          <h1>{busData?.busTittle ?? "Bus Title"}</h1>
          <div className={styles.img_box}>
            <img
              src={busData?.busImage}
              alt={busData?.meta_title}
              title={busData?.meta_title}
              loading="lazy"
              height="auto"
              width="auto"
            />
          </div>
          <span style={{ color: "gray" }} className={styles.date}>
            <span>
              Release Date :{" "}
              {busData?.createdAt ? formatBlogDate(busData?.createdAt) : "N/A"}
            </span>{" "}
            -{" "}
            <span>
              Updated Date :{" "}
              {busData?.updatedAt ? formatBlogDate(busData?.updatedAt) : "N/A"}
            </span>
          </span>
        </div>
                {/* Google AdSense Ad Placement */}
                <Box className={styles.adsenseBox}>
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-1396556165266132"
              data-ad-slot="4527423172"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </Box>
      </Box>
      <br />
      <Box className={styles.container}>
        {/* Left Section: Filter */}
        <Box className={styles.filterContainer}>
          <Container className={styles.container_list}>
            <Typography variant="h5" className={styles.title}>
              Table of Contents
            </Typography>
            <List className={styles.list}>
              <ListItem
                button
                onClick={() => handleScroll("section1")}
                className={styles.listItem}
                style={getActiveStyle("section1")}
              >
                <ListItemText
                  primary={busData?.section1?.title1 ?? "N/A"}
                  secondary={
                    <List className={styles.subList}>
                      <ListItem
                        button
                        onClick={() => handleScroll("subSection1A")}
                        className={styles.subListItem}
                        style={getActiveStyle("subSection1A")}
                      >
                        <ListItemText
                          primary={
                            <>
                              <span className={styles.dot}></span>
                              <span className={styles.subTitleText}>
                                {busData?.section1?.subSection1A?.title1A ??
                                  "N/A"}
                              </span>
                            </>
                          }
                          classes={{ primary: styles.smallFont }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => handleScroll("subSection1B")}
                        className={styles.subListItem}
                        style={getActiveStyle("subSection1B")}
                      >
                        <ListItemText
                          primary={
                            <>
                              <span className={styles.dot}></span>
                              <span className={styles.subTitleText}>
                                {busData?.section1?.subSection1B?.title1B ??
                                  "N/A"}
                              </span>
                            </>
                          }
                          classes={{ primary: styles.smallFont }}
                        />
                      </ListItem>
                    </List>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("section2")}
                className={styles.listItem}
                style={getActiveStyle("section2")}
              >
                <ListItemText
                  primary={busData?.section2?.title2 ?? "N/A"}
                  secondary={
                    <List className={styles.subList}>
                      <ListItem
                        button
                        onClick={() => handleScroll("subSection2A")}
                        className={styles.subListItem}
                        style={getActiveStyle("subSection2A")}
                      >
                        <ListItemText
                          primary={
                            <>
                              <span className={styles.dot}></span>
                              <span className={styles.subTitleText}>
                                {busData?.section2?.subSection2A?.title2A ??
                                  "N/A"}
                              </span>
                            </>
                          }
                          classes={{ primary: styles.smallFont }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => handleScroll("subSection2B")}
                        className={styles.subListItem}
                        style={getActiveStyle("subSection2B")}
                      >
                        <ListItemText
                          primary={
                            <>
                              <span className={styles.dot}></span>
                              <span className={styles.subTitleText}>
                                {busData?.section2?.subSection2B?.title2B ??
                                  "N/A"}
                              </span>
                            </>
                          }
                          classes={{ primary: styles.smallFont }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => handleScroll("subSection2C")}
                        className={styles.subListItem}
                        style={getActiveStyle("subSection2C")}
                      >
                        <ListItemText
                          primary={
                            <>
                              <span className={styles.dot}></span>
                              <span className={styles.subTitleText}>
                                {busData?.section2?.subSection2C?.title2C ??
                                  "N/A"}
                              </span>
                            </>
                          }
                          classes={{ primary: styles.smallFont }}
                        />
                      </ListItem>
                    </List>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("landMark")}
                className={styles.listItem}
                style={getActiveStyle("landMark")}
              >
                <ListItemText
                  primary={busData?.landMark?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("freqOfBus")}
                className={styles.listItem}
                style={getActiveStyle("freqOfBus")}
              >
                <ListItemText
                  primary={busData?.freqOfBus?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("nearByAttractions")}
                className={styles.listItem}
                style={getActiveStyle("nearByAttractions")}
              >
                <ListItemText
                  primary={busData?.nearByAttractions?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("safetyMeasures")}
                className={styles.listItem}
                style={getActiveStyle("safetyMeasures")}
              >
                <ListItemText
                  primary={busData?.safetyMeasures?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("dailyPassengersTips")}
                className={styles.listItem}
                style={getActiveStyle("dailyPassengersTips")}
              >
                <ListItemText
                  primary={busData?.dailyPassengersTips?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("specialNotes")}
                className={styles.listItem}
                style={getActiveStyle("specialNotes")}
              >
                <ListItemText
                  primary={busData?.specialNotes?.tittle ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("section3")}
                className={styles.listItem}
                style={getActiveStyle("section3")}
              >
                <ListItemText
                  primary={busData?.section3?.title3 ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleScroll("section4")}
                className={styles.listItem}
                style={getActiveStyle("section4")}
              >
                <ListItemText
                  primary={busData?.section4?.title4 ?? "N/A"}
                  classes={{ primary: styles.smallFont }}
                />
              </ListItem>
            </List>
          </Container>
        </Box>

        {/* Middle Section */}
        <Box className={styles.contentBox}>
          <Card className={styles.card}>
            <CardContent>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: busData?.busContent ?? "" }}
              />
              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="section1"
              >
                {busData?.section1?.title1 ?? "Section 1 Title"}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.section1?.description1 ?? "",
                }}
              />
              <TableContainer
                component={Paper}
                className={styles.tableContainer}
              >
                <Table aria-label="bus info table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography
                          variant="h6"
                          className={styles.subSectionTitle}
                        >
                          {busData?.section1?.subSection1A?.title1A ??
                            "Subsection 1A Title"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Bus Starts:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.busStarts1A ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Bus Ends:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.busEnds1A ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>First Bus:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.firstBus1A ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Last Bus:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.lastBus1A ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Total Stops:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.totalStops1A ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Total Departures:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1A?.totalDepartures1A ??
                          "N/A"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer
                component={Paper}
                className={styles.tableContainer}
              >
                <Table aria-label="bus info table section 1B">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography
                          variant="h6"
                          className={styles.subSectionTitle}
                        >
                          {busData?.section1?.subSection1B?.title1B ??
                            "Subsection 1B Title"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Bus Starts:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.busStarts1B ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Bus Ends:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.busEnds1B ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>First Bus:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.firstBus1B ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Last Bus:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.lastBus1B ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Total Stops:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.totalStops1B ?? "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Total Departures:</strong>
                      </TableCell>
                      <TableCell>
                        {busData?.section1?.subSection1B?.totalDepartures1B ??
                          "N/A"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <br />
              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="section2"
              >
                {busData?.section2?.title2 ?? "Section 2 Title"}
              </Typography>
              <Typography
                className={styles.description}
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: busData?.section2?.description2 ?? "",
                }}
              />

              <Typography
                variant="h6"
                className={styles.subSectionTitle}
                id="subSection2A"
              >
                {busData?.section2?.subSection2A?.title2A ??
                  "Subsection 2A Title"}
              </Typography>
              <Typography variant="body2">Up Route Stops:</Typography>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Stop No</th>
                    <th>Bus Stand Name</th>
                  </tr>
                </thead>
                <tbody>
                  {busData?.section2?.subSection2A?.busListUpRoute?.map(
                    (stop, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{stop}</td>
                      </tr>
                    )
                  ) ?? (
                    <tr>
                      <td colSpan="2">No stops available</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Typography
                variant="h6"
                className={styles.subSectionTitle}
                id="subSection2B"
              >
                {busData?.section2?.subSection2B?.title2B ??
                  "Subsection 2B Title"}
              </Typography>
              <Typography variant="body2">Down Route Stops:</Typography>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Stop No</th>
                    <th>Bus Stand Name</th>
                  </tr>
                </thead>
                <tbody>
                  {busData?.section2?.subSection2B?.busListDownRoute?.map(
                    (stop, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{stop}</td>
                      </tr>
                    )
                  ) ?? (
                    <tr>
                      <td colSpan="2">No stops available</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="subSection2C"
              >
                {busData?.section2?.subSection2C?.title2C}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.section2?.subSection2C?.description2C,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="landMark"
              >
                {busData?.landMark?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.landMark?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="freqOfBus"
              >
                {busData?.freqOfBus?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.freqOfBus?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="nearByAttractions"
              >
                {busData?.nearByAttractions?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.nearByAttractions?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="safetyMeasures"
              >
                {busData?.safetyMeasures?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.safetyMeasures?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="dailyPassengersTips"
              >
                {busData?.dailyPassengersTips?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData?.dailyPassengersTips?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="specialNotes"
              >
                {busData?.specialNotes?.tittle}
              </Typography>
              <Typography
                variant="body1"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: busData.specialNotes?.description,
                }}
              />

              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="section3"
              >
                {busData?.section3?.title3}
              </Typography>
              <br />
              {busData?.section3?.faq?.map((faqItem, index) => (
                <Accordion key={faqItem._id} className={styles.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.accordionSummary}
                  >
                    <Typography variant="h6" className={styles.faqQuestion}>
                      {faqItem?.que}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: faqItem?.ans }}
                      className={styles.faqAnswer}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}

              <br />
              <Typography
                variant="h2"
                className={styles.sectionTitle}
                id="section4"
              >
                {busData?.section4?.title4}
              </Typography>
              <br />
              <Typography variant="body1" className={styles.links}>
                {busData?.section2?.subSection2A?.busListUpRoute.map(
                  (stop, index) => (
                    <span key={index}>
                      <Link
                        to={`/bus-stand/${formatForUrl(stop)}`}
                        className={styles.link}
                      >
                        {stop}
                      </Link>
                      {index <
                        busData?.section2?.subSection2A?.busListUpRoute.length -
                          1 && ", "}
                    </span>
                  )
                )}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        {/* Right Section: Ads */}
        <Box className={styles.adsBox}>
          <Box className={styles.searchContainer}>
            <TextField
              variant="outlined"
              placeholder="Search by Bus Number"
              className={styles.searchBar}
              onChange={(e) => handleSearchBus(e)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          {showSearchBus && (
            <Box className={styles.searchResults}>
              <Typography variant="h6" className={styles.searchResultsTitle}>
                Search Results
              </Typography>
              <Box className={styles.searchResultsList}>
                {allBlogs
                  ?.filter((item) =>
                    item?.busNumber
                      .toLowerCase()
                      .includes(searchBus.toLowerCase())
                  )
                  .map((item) => (
                    <Button
                      key={item?.busNumber}
                      className={styles.searchResultItem}
                      variant="outlined"
                      onClick={() =>
                        (window.location.href = `https://easytripss.com/${formatTitleForUrl(
                          item?.busTittle
                        )}`)
                      }
                    >
                      {item?.busNumber}
                    </Button>
                  ))}
              </Box>
            </Box>
          )}
          <ButtonGroup
            className={styles.shareButtons}
            variant="text"
            aria-label="share buttons"
          >
            <IconButton onClick={() => handleShareClick("twitter")}>
              <TwitterIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("facebook")}>
              <FacebookIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("whatsapp")}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("linkedin")}>
              <LinkedInIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("instagram")}>
              <InstagramIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("pinterest")}>
              <PinterestIcon />
            </IconButton>
          </ButtonGroup>
          {/* Google AdSense Ad Placement */}
          <ins
            className="adsbygoogle"
            style={{ display: "block", margin: "20px 0" }}
            data-ad-client="ca-pub-1396556165266132"
            data-ad-slot="6270849829"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Box>
      </Box>
      {/* Footer */}
      <br />
      <h2>Related Searches You May Like</h2>
      <div className={styles.busCardList}>
        <Grid container spacing={3}>
          {allBlogs.length > 0 ? (
            allBlogs.slice(0, visibleCards).map((bus) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={bus?._id}>
                <BusCard
                  busNumber={bus?.busNumber}
                  busTitle={bus?.busTittle}
                  busImage={bus?.busImage}
                  description={bus?.busContent}
                  createdAt={bus?.createdAt}
                  updatedAt={bus?.updatedAt}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <p>No buses found</p>
            </Grid>
          )}
        </Grid>

        {allBlogs.length > 8 && (
          <div className={styles.buttonContainer}>
            {visibleCards < allBlogs.length ? (
              <Button
                variant="contained"
                onClick={showMore}
                className={styles.showMoreButton}
              >
                Show More
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={showLess}
                className={styles.showLessButton}
              >
                Show Less
              </Button>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FilterBusPage;
