import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './BusCard.module.css';

const BusCard = ({busNumber, busTitle, busImage, description, createdAt, updatedAt }) => {
  const navigate = useNavigate();

  const truncateText = (text, maxLines) => {
    const lineHeight = 1.5; // Approximate line height for calculation
    const maxChars = 90 * maxLines; // Approximate max characters per line
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + "...";
    }
    return text;
  };

  const handleCardClick = () => {
    const formattedTitle = busTitle
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove special characters
      .trim() // Trim leading/trailing whitespace
      .replace(/\s+/g, '-'); // Replace spaces with hyphens

    const encodedTitle = encodeURIComponent(formattedTitle);
    navigate(`/${encodedTitle}`);

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Card className={styles.card} onClick={handleCardClick}>
      <div className={styles.cardMedia}>
      <img
        src={busImage || "defaultImage.jpg"} // Fallback image if busImage is undefined
        alt={busTitle || "Bus Image"}
        title={busTitle}
        loading="lazy"
        height="auto"
        width={"100%"}
      />
      </div>
 
      <CardContent className={styles.cardContent}>
        <Typography 
          variant="h5" 
          component="div" 
          className={styles.busTitle}
          title={busTitle} // Show full title on hover
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3, // Limit to 3 lines
            WebkitBoxOrient: 'vertical',
          }}
        >
          {truncateText(busTitle || "Unknown Bus", 3)}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: truncateText(description || "No description available.", 3),
          }}
        />
        <Box className={styles.dates}>
          <Typography variant="caption" color="text.secondary">
            Created: {createdAt ? new Date(createdAt).toLocaleDateString() : "N/A"}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Updated: {updatedAt ? new Date(updatedAt).toLocaleDateString() : "N/A"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BusCard;
