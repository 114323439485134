import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styles from "./Districts.module.css"; // Import the module-level CSS
import LocationSearch from "./LocationSearch";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Districts = () => {
  const { state, district } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Normalize parameters
    const normalizedState = state.replace(/-/g, " ");
    const normalizedDistrict = district ? district.replace(/-/g, " ") : "";

    // Fetch post office data
    axios
      .get(
        ` https://www.backend.easytripss.com/public/pincode/allData?state=${normalizedState}&city=${normalizedDistrict}`
      )
      .then((response) => {
        // Set data for post offices
        setData(response.data.data.map((item) => item.postOfficeName));
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, [state, district]);

  // Convert spaces to hyphens
  const formatForURL = (text) => {
    return text.trim().replace(/\s+/g, "-");
  };

  if (loading) {
    return (
      <Box className={styles.loading}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box className={styles.error}>{error}</Box>;
  }

  return (
    <>
      <NavBar />
      <Box className={styles.container}>
        <Helmet>
          <title>
            {district
              ? `Post Offices in ${district} District`
              : "All Post Offices"}{" "}
            in {state}
          </title>
          <link
            rel="canonical"
            href={`https://easytripss.com/pincode/${state}${
              district ? `/${district}` : ""
            }`}
          />
          <meta
            name="description"
            content={`Discover all post offices in ${
              district ? `${district}, ` : ""
            }${state}. Find detailed information about post offices including their addresses.`}
          />
          <meta
            name="keywords"
            content={`${
              district ? `${district} ` : ""
            }post offices in ${state}, ${state} postal services, ${
              district ? `${district} ` : ""
            }post office details`}
          />
          <meta
            property="og:title"
            content={`${
              district
                ? `Post Offices in ${district} District`
                : "All Post Offices"
            } in ${state}`}
          />
          <meta
            property="og:description"
            content={`Discover all post offices in ${
              district ? `${district}, ` : ""
            }${state}. Find detailed information about post offices including their addresses.`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://easytripss.com/pincode/${state}${
              district ? `/${district}` : ""
            }`}
          />
          <meta
            property="og:image"
            content="https://easytripss.com/og-image.jpg"
          />
        </Helmet>
        <div className={styles.heading_box}>
          <Typography variant="h1" gutterBottom className={styles.title}>
            {district
              ? `Post Offices in ${district} District`
              : "All Post Offices"}{" "}
            in {state}
          </Typography>
          <Typography variant="h6" paragraph>
            Explore the comprehensive list of post offices in{" "}
            {district ? `${district}, ` : ""}
            {state}. Find detailed information including addresses and contact
            details.
          </Typography>
        </div>
        <div className={styles.container_box}>
          <LocationSearch />
          <TableContainer component={Paper}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Post Office</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((postOffice, index) => (
                  <TableRow
                    key={index}
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href = `/pincode/${state}/${district}/${formatForURL(
                        postOffice
                      )}`)
                    }
                  >
                    <TableCell>{postOffice}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default Districts;
