import React, { useEffect } from "react";
import { Container, Typography, Grid, Box, Paper, Avatar } from "@mui/material";
import styles from "./AboutUsPage.module.css";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Helmet } from "react-helmet-async";

const teamMembers = [
  {
    name: "John Doe",
    role: "Founder & CEO",
    image:
      "https://w7.pngwing.com/pngs/378/16/png-transparent-profile-profile-picture-human-face-head-man-woman-community-outline-schema-thumbnail.png",
    bio: "John has over 10 years of experience in the travel industry and is passionate about helping people explore the world.",
  },
  {
    name: "Jane Smith",
    role: "Chief Marketing Officer",
    image:
      "https://w7.pngwing.com/pngs/378/16/png-transparent-profile-profile-picture-human-face-head-man-woman-community-outline-schema-thumbnail.png",
    bio: "Jane is a marketing expert with a knack for creating unforgettable experiences for our customers.",
  },
  {
    name: "Alex Johnson",
    role: "Lead Developer",
    image:
      "https://w7.pngwing.com/pngs/378/16/png-transparent-profile-profile-picture-human-face-head-man-woman-community-outline-schema-thumbnail.png",
    bio: "Alex is the tech guru behind our website, ensuring a smooth and user-friendly experience.",
  },
];

const AboutUsPage = () => {
  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);
  return (
    <div className={styles.main}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossorigin="anonymous"
        ></script>
        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>

        {/* Title and Meta Tags */}
        <title>About Us - EasyTripss | Your Trusted Travel Guide</title>
        <meta
          name="description"
          content="Learn more about EasyTripss, your trusted source for travel guides, tips, and destination recommendations."
        />
        <meta
          name="keywords"
          content="Delhi bus routes, bus stands in Delhi, Delhi bus stops, bus information Delhi, find bus routes Delhi, local bus routes Delhi, Delhi public transport, bus schedule Delhi, Delhi bus tracker, nearby bus stands"
        />
        <link rel="canonical" href="https://easytripss.com/about" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="About Us - EasyTripss | Your Trusted Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover the team behind EasyTripss and our mission to make travel easier for everyone."
        />
        <meta property="og:url" content="https://easytripss.com/about" />
        <meta
          property="og:image"
          content="https://easytripss.com/images/about-us-og.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About Us - EasyTripss | Your Trusted Travel Guide"
        />
        <meta
          name="twitter:description"
          content="Meet the team that brings you the best travel guides, tips, and destination recommendations."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/images/about-us-twitter.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />

        {/* Schema.org JSON-LD for WebSite */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            potentialAction: {
              "@type": "SearchAction",
              target:
                "https://easytripss.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>

        {/* Schema.org JSON-LD for Organization */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            logo: "https://easytripss.com/logo.png",
            sameAs: [
              "https://www.facebook.com/EasyTripss",
              "https://twitter.com/EasyTripss",
              "https://www.instagram.com/EasyTripss",
            ],
            description:
              "EasyTripss is a travel platform dedicated to providing top-notch travel guides, tips, and destination recommendations.",
          })}
        </script>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <NavBar />

      <Container className={styles.container}>
        <Typography variant="h1" component="h1" className={styles.title}>
          About Us
        </Typography>
        <Typography variant="h4" component="h2" className={styles.sectionTitle}>
          Welcome to Easytripss
        </Typography>
        <Typography variant="body1" className={styles.text}>
          Welcome to Easytripss, the definitive guide to navigating Delhi
          Transport Corporation's (DTC) extensive and dynamic bus routes.
          Whether you're a daily commuter, a tourist exploring Delhi's vibrant
          streets, or a casual traveler looking for the most convenient way to
          get around the city, our platform is designed to be your go-to source
          for all things DTC bus lines.
        </Typography>
        <Typography variant="h4" component="h2" className={styles.sectionTitle}>
          OUR MISSION
        </Typography>
        <Typography variant="body1" className={styles.text}>
          Our mission at Easytripss is to simplify public transport in Delhi. We
          understand that navigating the city's extensive bus network can be
          overwhelming, especially for newcomers. We aim to make it easier for
          everyone to plan their journeys, save time and make the most of
          Delhi's public transport system. We provide accurate and up-to-date
          information on bus routes, timetables, fares and key landmarks on each
          route to ensure your DTC bus experience is smooth and hassle-free.
        </Typography>

        <Typography variant="h4" component="h2" className={styles.sectionTitle}>
          WHO WE ARE
        </Typography>
        <Typography variant="body1" className={styles.text}>
          We are a dedicated team of public transport enthusiasts, urban
          planners and technology experts who share a common passion for making
          Delhi a more accessible and connected city. Our team is committed to
          providing reliable, user-friendly and comprehensive information to
          help you navigate the Delhi bus system with confidence. With years of
          experience in transport and urban mobility, we have a deep
          understanding of the challenges commuters face and the solutions
          needed to address them.
        </Typography>

        <Typography variant="h4" component="h2" className={styles.sectionTitle}>
          WHAT WE OFFER
        </Typography>
        <Typography variant="body1" className={styles.text}>
          <strong>Detailed Bus Route Information</strong>
          <br />
          Our website offers an extensive database of DTC bus routes, including
          detailed information about each route's stops, key landmarks, travel
          times and frequency. Whether you're looking for the fastest route to
          your destination or want to explore the city's attractions by bus,
          we've got you covered.
        </Typography>
        {/* Google AdSense Ad Placement */}
        <ins
          className="adsbygoogle"
          style={{ display: "block", textAlign: "center", margin: "20px 0" }}
          data-ad-client="ca-pub-1396556165266132"
          data-ad-slot="1234567890" // Replace with your ad slot ID
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <Typography variant="body1" className={styles.text}>
          <strong>Real-time Updates</strong>
          <br />
          Stay informed with real-time updates on bus schedules, delays and
          route changes. We closely monitor the DTC network to provide you with
          the latest information and ensure you are always informed of any
          changes that could affect your journey.
        </Typography>
        <Typography variant="body1" className={styles.text}>
          <strong>Fare Information</strong>
          <br />
          We provide detailed fare information for all DTC bus routes so you
          know exactly what to expect before you board. From standard fares to
          discounts for students, seniors and special categories, we make sure
          you have all the information you need to budget for your journey.
        </Typography>
        <Typography variant="body1" className={styles.text}>
          <strong>User Friendly Tools</strong>
          <br />
          Our website includes interactive tools that allow you to search for
          routes by destination, landmark or bus number. You can also use our
          route planner to find the most efficient route to your destination,
          including estimated travel times and fare calculations.
        </Typography>
        <Typography variant="body1" className={styles.text}>
          <strong>Commuter Tips and Guides</strong>
          <br />
          In addition to route information, we offer helpful tips and guides for
          navigating the Delhi public transport system. From understanding bus
          maps to maximizing your travel experience, our resources are designed
          to enhance your journey.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
