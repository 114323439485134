import React, { useEffect } from "react";
import { Container, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import Footer from "../Footer";
import NavBar from "../NavBar";
import styles from "./DisclaimerPage.module.css";

const DisclaimerPage = () => {
  const theme = useTheme();

  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossOrigin="anonymous"
        ></script>
        {/* Title and Meta Tags */}
        <title>EasyTripss - Disclaimer | Important Information</title>
        <meta
          name="description"
          content="Read the EasyTripss Disclaimer to understand our relationship with DTC and the limitations of the information provided on our website."
        />
        <meta
          name="keywords"
          content="disclaimer, EasyTripss disclaimer, DTC, information accuracy, website content"
        />
        <link rel="canonical" href="https://easytripss.com/disclaimer" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EasyTripss - Disclaimer | Important Information"
        />
        <meta
          property="og:description"
          content="Learn about EasyTripss's disclaimer regarding our non-affiliation with DTC and the limitations of the information provided."
        />
        <meta
          property="og:url"
          content="https://easytripss.com/disclaimer"
        />
        <meta
          property="og:image"
          content="https://easytripss.com/disclaimer-image.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="EasyTripss - Disclaimer | Important Information"
        />
        <meta
          name="twitter:description"
          content="Our Disclaimer explains EasyTripss's non-affiliation with DTC and the limitations of the information provided on our site."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/disclaimer-image.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />
      </Helmet>
      <NavBar />
      <Container className={styles.container}>
        <Typography variant="h2" className={styles.title}>
          Disclaimer
        </Typography>
        <Paper className={styles.paper}>
          <Typography variant="body1" className={styles.text}>
            Easytripss is an independent website and is not officially affiliated with the Delhi Transport Corporation (DTC) or any of its associated organizations, nor does it receive any endorsement or support from these entities.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            The information provided on Easytripss.com is intended solely for informational purposes. While we strive to offer useful and timely details about DTC bus routes, we cannot guarantee the accuracy or completeness of the information. The website may include links to external sites for convenience, but we are not responsible for the content of these linked sites.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We make every effort to keep the information on Easytripss.com accurate and up-to-date, but we cannot ensure that it is free from all errors. We are not liable for any damages or losses resulting from the use of this website or the information it contains.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We reserve the right to modify, update, or remove any information on Easytripss at any time without prior notice. We also retain the right to change the website's focus or discontinue it entirely.
          </Typography>
          <Typography variant="body1" className={styles.text}>
            We appreciate your feedback and welcome any suggestions to improve the site. Please inform us of any errors or issues you encounter so we can address them promptly.
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default DisclaimerPage;
