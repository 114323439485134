import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  ButtonGroup,
  IconButton,
  Button,
} from "@mui/material";
import styles from "./AllBusStand.module.css";
import Footer from "../Footer";
import NavBar from "../NavBar";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import Breadcrumbs from "../Breadcrumbs";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import FAQSection from "../sections/HomePageSection/FAQSection";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AllBusStand = () => {
  const { selectedCity } = useParams();
  const [busData, setBusData] = useState({});
  const [allBlogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleItems, setVisibleItems] = useState(16);
  const [visibleItems2, setVisibleItems2] = useState(16);
  const [showMore, setShowMore] = useState(true);
  const [showMore2, setShowMore2] = useState(true);
  const [searchBus, setSearchBus] = useState("");
  const [showSearchBus, setShowSearchBus] = useState(false);
 
  // Format the 'from' and 'to' values
  const formattedSelectedCity = selectedCity.replace(/-/g, " ");
  const apiUrl = ` https://www.backend.easytripss.com/public/bus/busesByStops?busStop=${formattedSelectedCity}`;
  const baseUrl = "https://easytripss.com";
  const shareUrl = `${baseUrl}/bus-stand/${selectedCity}`;

  useEffect(() => {
    // Fetch all blogs (if needed)
    axios
      .get(` https://www.backend.easytripss.com/public/bus/getAllBus`)
      .then((response) => {
        if (response.data.status) {
          setBlogs(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching bus data:", error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // Fetch bus data based on 'from' and 'to' values
    axios
      .get(apiUrl)
      .then((response) => {
        setBusData(response.data);
      })
      .catch((error) => console.error("Error fetching bus data:", error))
      .finally(() => setLoading(false));
  }, [apiUrl]);

  const handleShareClick = (platform) => {
    const urls = {
      twitter: `https://twitter.com/share?url=${shareUrl}&text=Check out this bus on Easytripss!`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      whatsapp: `https://api.whatsapp.com/send?text=Check out this bus on Easytripss! ${shareUrl}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=Check out this bus on Easytripss!`,
      instagram: `https://www.instagram.com/?url=${shareUrl}`,
      pinterest: `https://pinterest.com/pin/create/button/?url=${shareUrl}&description=Check out this bus on Easytripss!`,
    };
    window.open(urls[platform], "_blank");
  };

  const handleShowMore = () => {
    setVisibleItems(busData?.data?.length || 8);
    setShowMore(false);
  };

  const handleShowLess = () => {
    setVisibleItems(8);
    setShowMore(true);
  };

  const handleShowMore2 = () => {
    setVisibleItems2(busData?.data?.length || 8);
    setShowMore2(false);
  };

  const handleShowLess2 = () => {
    setVisibleItems2(8);
    setShowMore2(true);
  };
  useEffect(() => {
    // Initialize Google Ads
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);
  const handleSearchBus = (e) => {
    setSearchBus(e.target.value);
    if (e.target.value.length > 0) setShowSearchBus(true);
    if (e.target.value.length === 0) setShowSearchBus(false);
  };

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, "") // Remove special characters
      .trim() // Trim leading/trailing whitespace
      .replace(/\s+/g, "-"); // Replace spaces with hyphens
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={styles.main}>
      <Helmet>
     {/* Google AdSense Script */}
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          data-ad-client="ca-pub-1396556165266132"
          crossorigin="anonymous"
        ></script>
        {/* Google Tag Manager Script */}
        <script>
          {`
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
    `}
        </script>

        <title>
          {formattedSelectedCity} Bus Stops - stand, Routes, Schedules Delhi
          DTC Bus - Timing
        </title>
        <meta
          name="description"
          content={busData?.additionalData?.stand?.description}
        />
        <meta
          name="keywords"
          content={busData?.additionalData?.stand?.description}
        />
        <meta name="author" content="Easy Tripss" />
        <meta name="robots" content="index, follow" />

        <link rel="canonical" href={shareUrl} />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content={`${formattedSelectedCity} Bus Stops - stand, Routes, Schedules Delhi DTC Bus - Timing`}
        />
        <meta
          property="og:description"
          content={busData?.additionalData?.stand?.description}
        />
        <meta
          property="og:image"
          content={busData?.busImage || "default-image.jpg"}
        />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${formattedSelectedCity} Bus Stops - stand, Routes, Schedules Delhi DTC Bus - Timing`}
        />
        <meta
          name="twitter:description"
          content={busData?.additionalData?.stand?.description}
        />
        <meta
          name="twitter:image"
          content={busData?.busImage || "default-image.jpg"}
        />

        {/* Schema Markup for BlogPosting */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": { shareUrl },
            },
            headline: `${formattedSelectedCity} Bus Stops - stand, Routes, Schedules Delhi DTC Bus - Timing`,
            image: busData?.busImage || "default-image.jpg",
            author: {
              "@type": "Organization",
              name: "Easy Tripss",
            },
            publisher: {
              "@type": "Organization",
              name: "Easy Tripss",
              logo: {
                "@type": "ImageObject",
                url: "https://easytripss.com/logo.png", // Replace with your logo URL
              },
            },
            description: busData?.additionalData?.stand?.description,
            articleBody: busData?.additionalData?.stand?.description,
            mainEntity: {
              "@type": "FAQPage",
              name: "FAQs about Bus Routes",
              mainEntity: busData?.additionalData?.faq?.map((item) => ({
                "@type": "Question",
                name: item?.q,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: item?.a,
                },
              })),
            },
          })}
        </script>
      </Helmet>

      <NavBar />
      <Breadcrumbs />
      <Box className={styles.titleBox}>
        <Typography variant="h1" className={styles.title}>
          {formattedSelectedCity} Bus Stops - stand, Routes, Schedules Delhi
          DTC Bus - Timing
        </Typography>
        <p className={styles.des}>{busData.additionalData.stand.description}</p>
      </Box>
      <br />
      <Box className={styles.container}>
        {/* Left Section: Filter */}
        <Box className={styles.filterContainer}>
          <Typography variant="h5"></Typography>
        </Box>

        {/* Middle Section */}
        <Box className={styles.contentBox}>
          <div className={styles.list_box}>
            {busData?.data?.slice(0, visibleItems).map((bus, index) => (
              <ul>
                <li
                  onClick={() =>
                    (window.location.href = `/${formatTitleForUrl(
                      bus?.busTittle
                    )}`)
                  }
                >
                  <strong>Bus No: {bus?.busNumber}</strong> - <span className={styles.card_title}>{bus?.busTittle}</span>
                </li>
              </ul>
            ))}
          </div>

          <Box className={styles.showMoreContainer}>
            {showMore ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowMore}
              >
                Show More
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleShowLess}
              >
                Show Less
              </Button>
            )}
          </Box>
          <br />
          {busData?.data?.slice(0, visibleItems2).map((bus, index) => (
            <Card className={styles.card}>
              <CardContent>
                <strong>Bus No: {bus?.busNumber}</strong>
                <Typography variant="h6">{bus?.busTittle}</Typography>
                <div className={styles.busImage}>
                  <img
                    src={bus?.busImage}
                    alt={bus?.busTittle}
                    title={bus?.busTittle}
                    loading="lazy"
                    width="auto"
                    height="auto"
                  />
                </div>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: bus?.busContent }}
                />
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  (window.location.href = `/${formatTitleForUrl(
                    bus?.busTittle
                  )}`)
                }
              >
                Continue reading...
              </Button>
            </Card>
          ))}
          <br />
          <Box className={styles.showMoreContainer}>
            {showMore2 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowMore2}
              >
                Show More
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleShowLess2}
              >
                Show Less
              </Button>
            )}
          </Box>
        </Box>

        {/* Right Section: Ads */}
        <Box className={styles.adsBox}>
          <Box className={styles.searchContainer}>
            <TextField
              variant="outlined"
              placeholder="Search by Bus Number"
              className={styles.searchBar}
              onChange={(e) => handleSearchBus(e)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          {showSearchBus && (
            <Box className={styles.searchResults}>
              <Typography variant="h6" className={styles.searchResultsTitle}>
                Search Results
              </Typography>
              <Box className={styles.searchResultsList}>
                {allBlogs
                  ?.filter((item) =>
                    item?.busNumber
                      .toLowerCase()
                      .includes(searchBus.toLowerCase())
                  )
                  .map((item) => (
                    <Button
                      key={item?.busNumber}
                      className={styles.searchResultItem}
                      variant="outlined"
                      onClick={() =>
                        (window.location.href = `https://easytripss.com/${formatTitleForUrl(
                          item?.busTittle
                        )}`)
                      }
                    >
                      {item?.busNumber}
                    </Button>
                  ))}
              </Box>
            </Box>
          )}
          <ButtonGroup
            className={styles.shareButtons}
            variant="text"
            aria-label="share buttons"
          >
            <IconButton onClick={() => handleShareClick("twitter")}>
              <TwitterIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("facebook")}>
              <FacebookIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("whatsapp")}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("linkedin")}>
              <LinkedInIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("instagram")}>
              <InstagramIcon />
            </IconButton>
            <IconButton onClick={() => handleShareClick("pinterest")}>
              <PinterestIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      </Box>
      <br />
      <section className={styles.section}>
        <h2>{busData?.additionalData?.overView?.overViewTitle}</h2>
        <p>{busData?.additionalData?.overView?.overviewDescription}</p>
      </section>
      <section className={styles.section}>
        <h2>{busData?.additionalData?.busRoute?.routeTitle}</h2>
        <p>{busData?.additionalData?.busRoute?.routeDescription}</p>
      </section>
      <section className={styles.section}>
        <h2>{busData?.additionalData?.schedule?.scheduleTitle}</h2>
        <p>{busData?.additionalData?.schedule?.scheduleDescription}</p>
      </section>
      <section className={styles.section}>
        <h2>{busData?.additionalData?.connectivity?.connectivityTitle}</h2>
        <p>{busData?.additionalData?.connectivity?.connectivityDescription}</p>
        <h4>
          {busData?.additionalData?.connectivity?.metroStation?.metroTitle}
        </h4>
        <p>
          {busData?.additionalData?.connectivity?.metroStation?.metroDescription}
        </p>
        <h4>
          {busData?.additionalData?.connectivity?.bankStreet?.bankStreetTitle}
        </h4>
        <p>
          {busData?.additionalData?.connectivity?.bankStreet?.bankStreetDescription}
        </p>
        <h4>
          {busData?.additionalData?.connectivity?.ajmalKhanRoad?.ajmalKhanRoadTitle}
        </h4>
        <p>
          {busData?.additionalData?.connectivity?.ajmalKhanRoad?.ajmalKhanRoadDescription}
        </p>
      </section>
      <br/>
      <FAQSection faqs={busData} />
      <br/>
      <Footer />
    </div>
  );
};

export default AllBusStand;
