import React, { useEffect } from "react";
import { Container, Typography, Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import NavBar from "../NavBar";
import Footer from "../Footer";
import styles from "./TermsAndConditionsPage.module.css";

const TermsAndConditionsPage = () => {
  useEffect(() => {
    try {
      // Initialize Google Ads
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Google Ads initialization error:", e);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Helmet>
        {/* Google AdSense */}
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1396556165266132"
          crossOrigin="anonymous"
        ></script>

        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T7Q7PW8K');
          `}
        </script>

        {/* Meta Tags */}
        <title>EasyTripss - Terms and Conditions | Your Agreement with Us</title>
        <meta
          name="description"
          content="Read the EasyTripss Terms and Conditions to understand your obligations and rights while using our website. Your agreement with EasyTripss is outlined here."
        />
        <meta
          name="keywords"
          content="terms and conditions, EasyTripss, user agreement, legal, website terms"
        />
        <link
          rel="canonical"
          href="https://easytripss.com/terms-and-conditions"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EasyTripss - Terms and Conditions | Your Agreement with Us"
        />
        <meta
          property="og:description"
          content="Understand the terms and conditions that govern your use of EasyTripss. This page outlines your obligations and rights."
        />
        <meta
          property="og:url"
          content="https://easytripss.com/terms-and-conditions"
        />
        <meta
          property="og:image"
          content="https://easytripss.com/terms-and-conditions-image.jpg"
        />
        <meta property="og:site_name" content="EasyTripss" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="EasyTripss - Terms and Conditions | Your Agreement with Us"
        />
        <meta
          name="twitter:description"
          content="Our Terms and Conditions page explains the rules and regulations for using the EasyTripss website."
        />
        <meta
          name="twitter:image"
          content="https://easytripss.com/terms-and-conditions-image.jpg"
        />
        <meta name="twitter:site" content="@EasyTripss" />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Terms and Conditions",
            url: "https://easytripss.com/terms-and-conditions",
            potentialAction: {
              "@type": "ReadAction",
              target: "https://easytripss.com/terms-and-conditions",
            },
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "EasyTripss",
            url: "https://easytripss.com/",
            logo: "https://easytripss.com/logo.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-800-555-1234",
              contactType: "Customer Service",
              areaServed: "US",
              availableLanguage: "English",
            },
            sameAs: [
              "https://www.facebook.com/EasyTripss",
              "https://twitter.com/EasyTripss",
              "https://www.instagram.com/EasyTripss",
            ],
          })}
        </script>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T7Q7PW8K"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>

      <NavBar />

      <Container className={styles.container}>
        <Typography variant="h1" className={styles.title}>
          Terms and Conditions
        </Typography>
        <Paper className={styles.paper}>
          <Typography variant="h2" className={styles.sectionTitle}>
            Welcome to EasyTripss
          </Typography>
          <Typography className={styles.paragraph}>
            Welcome to EasyTripss, your reliable source for information on Delhi
            Transport Corporation (DTC) bus routes. By accessing and using our
            website, you agree to comply with the following terms and
            conditions.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            Intellectual Property
          </Typography>
          <Typography className={styles.paragraph}>
            All information on EasyTripss.com, including text, images, and
            multimedia, is protected by copyright and intellectual property
            laws. Unauthorized copying, distribution, or modification of this
            content is strictly prohibited without prior written consent from
            EasyTripss.com.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            Accuracy of Information
          </Typography>
          {/* Google AdSense Ad Placement */}
          <ins
            className="adsbygoogle"
            style={{ display: "block", textAlign: "center", margin: "20px 0" }}
            data-ad-client="ca-pub-1396556165266132"
            data-ad-slot="1234567890" // Replace with your ad slot ID
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <Typography className={styles.paragraph}>
            While we strive to provide accurate and up-to-date information,
            EasyTripss does not guarantee the completeness or accuracy of the
            data provided. We recommend verifying details independently.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            External Links
          </Typography>
          <Typography className={styles.paragraph}>
            EasyTripss may contain links to external websites for your
            convenience. However, we do not endorse or take responsibility for
            the content of these external sites. We reserve the right to modify
            or update our website, its content, and these terms and conditions
            at any time without prior notice. Your continued use of the website
            constitutes acceptance of any changes.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            Data Protection and Privacy
          </Typography>
          <Typography className={styles.paragraph}>
            We value your privacy and adhere to applicable data protection laws.
            EasyTripss.com may collect and store data such as IP addresses and
            usage statistics for analysis and improvement. We take measures to
            ensure the security of your personal information but encourage you
            to protect your account credentials.
          </Typography>
          <Typography variant="h2" className={styles.sectionTitle}>
            Unauthorized Use
          </Typography>
          <Typography className={styles.paragraph}>
            Unauthorized use of the website or violations of these terms may
            result in legal action. By using EasyTripss.com, you agree to these
            terms and conditions.
          </Typography>
        </Paper>
      </Container>

      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
