import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import LocationSearch from './LocationSearch';
import NavBar from '../NavBar';
import Footer from '../Footer';
import styles from './Pincode.module.css';

// Function to render FAQ entries
const FAQSection = () => (
  <section className={styles.faqSection}>
    <Typography variant="h2" className={styles.title}>Frequently Asked Questions</Typography>
    <Paper elevation={3} style={{ padding: '1rem' }}>
      <dl>
        <dt><Typography variant="h6">What is a Pincode?</Typography></dt>
        <dd>A Pincode (Postal Index Number) is a six-digit code in the Indian postal code system used by the postal department to simplify mail delivery.</dd>

        <dt><Typography variant="h6">How can I find my Pincode?</Typography></dt>
        <dd>You can find your Pincode by using the location search tool on this page or by referring to the postal code directory provided by the Indian postal service.</dd>

        <dt><Typography variant="h6">Why is Pincode important?</Typography></dt>
        <dd>The Pincode helps in accurate and efficient mail sorting and delivery by the postal department, ensuring that your mail reaches the correct address.</dd>
      </dl>
    </Paper>
  </section>
);

const Pincode = () => {
  return (
    <div>
      <Helmet>
        <title>Pincode Lookup | Find Your Pincode | Your Bus</title>
        <meta name="description" content="Find your Pincode using our easy-to-use location search tool. Get accurate and updated Pincode information for various locations." />
        <meta name="keywords" content="Pincode, Postal Index Number, Pin Code, Location Search" />
        <link rel="canonical" href="https://yourbuswebsite.com/pincode" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Pincode Lookup",
            "description": "Find your Pincode using our easy-to-use location search tool. Get accurate and updated Pincode information for various locations.",
            "url": "https://yourbuswebsite.com/pincode",
            "mainEntity": {
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What is a Pincode?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A Pincode (Postal Index Number) is a six-digit code in the Indian postal code system used by the postal department to simplify mail delivery."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How can I find my Pincode?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can find your Pincode by using the location search tool on this page or by referring to the postal code directory provided by the Indian postal service."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Why is Pincode important?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Pincode helps in accurate and efficient mail sorting and delivery by the postal department, ensuring that your mail reaches the correct address."
                  }
                }
              ]
            }
          }
          `}
        </script>
      </Helmet>
      <NavBar />
      <Container className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" className={styles.title}>Pincode Lookup</Typography>
        </div>
        <LocationSearch />
        <section className={styles.section}>
          <Typography variant="h2" className={styles.title}>About Pincode in India</Typography>
          <Typography paragraph>
            India is a vast nation with 28 states and at least 718 districts. To identify regions, the Indian postal department has allotted a unique code known as Pin Code to each district/village/town to aid delivery of postal services. Pin Code stands for Postal Index Number Code. Also known as Zip code or the area postal code, it holds importance as the entire postal system of the country works around it. This code is made up of six numbers offering complete information about the area where you reside. Each of these numbers is designed for a specific area. India is divided into six special zones, and each pin code gives information of one or the other special zone. It indicates the state, district, and name of the Post Office.
          </Typography>

          <Typography variant="h2" className={styles.title}>Why You Need a Pincode</Typography>
          <Typography paragraph>
            India is a large country, and hence, it is next to impossible to recall the Pincode of any particular locality in a city/town or village or to locate a place using its Pincode. To help you find a Pincode or area, we present to you our Pincode search engine. Now, you can easily search for the Pincode of a particular area as well as the address of any place in India, simply through its Pincode. All of that is just one click away.
          </Typography>

          <Typography paragraph>
            With this section, our aim is to cater to the needs of individuals as well as organizations who want to search for Pincode or addresses of localities across India. Be it a place in the remote mountains of Jammu and Kashmir or the deserts of Rajasthan, we have got you covered.
          </Typography>

          <Typography variant="h2" className={styles.title}>State Wise Pincode List</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['Andaman & Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra & Nagar Haveli', 'Daman & Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu & Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Orissa', 'Pondicherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'].map(state => (
                  <TableRow key={state}>
                    <TableCell>{state}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h2" className={styles.title}>Pin Code List - Postal Index Number Codes</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pin Code Range</TableCell>
                  <TableCell>States</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  { range: '11', states: 'Delhi' },
                  { range: '12 - 13', states: 'Haryana' },
                  { range: '14 - 16', states: 'Punjab' },
                  { range: '17', states: 'Himachal Pradesh' },
                  { range: '18 - 19', states: 'Jammu & Kashmir' },
                  { range: '20 - 28', states: 'Uttar Pradesh' },
                  { range: '30 - 34', states: 'Rajasthan' },
                  { range: '36 - 39', states: 'Gujarat' },
                  { range: '0 - 44', states: 'Maharashtra' },
                  { range: '45 - 48', states: 'Madhya Pradesh' },
                  { range: '49', states: 'Chhattisgarh' },
                  { range: '50 - 53', states: 'Andhra Pradesh, Telangana' },
                  { range: '56 - 59', states: 'Karnataka' },
                  { range: '60 - 64', states: 'Tamil Nadu' },
                  { range: '67 - 69', states: 'Kerala' },
                  { range: '682', states: 'Lakshadweep' },
                  { range: '70 - 74', states: 'West Bengal' },
                  { range: '744', states: 'Andaman & Nicobar' },
                  { range: '75 - 77', states: 'Orissa' },
                  { range: '78', states: 'Assam' },
                  { range: '79', states: 'Arunachal Pradesh, Manipur, Meghalaya, Mizoram, Nagaland, Tripura' },
                  { range: '80 - 85', states: 'Bihar' },
                  { range: '80 - 83, 92', states: 'Jharkhand' }
                ].map(({ range, states }) => (
                  <TableRow key={range}>
                    <TableCell>{range}</TableCell>
                    <TableCell>{states}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h2" className={styles.title}>What is a Pin (Postal Index Number)</Typography>
          <Typography paragraph>
            While sending a letter or a parcel via the Indian postal system or by courier, do you wonder if it will safely reach its destination? The chances of your letter reaching its intended recipient are increased every time you mention the correct Pincode of the recipient's location. It helps the postal services to accurately sort and deliver your mail to the correct address. The Indian postal system is divided into numerous Pin Codes which are used to efficiently deliver letters and parcels to the right address.
          </Typography>

          <Typography variant="h2" className={styles.title}>Why is Pincode Important?</Typography>
          <Typography paragraph>
            The Pincode helps in accurate and efficient mail sorting and delivery by the postal department, ensuring that your mail reaches the correct address. In India, Pincodes are used to pinpoint locations in order to facilitate smooth and hassle-free mail delivery.
          </Typography>
        </section>

        <FAQSection />
      </Container>
      <Footer />
    </div>
  );
};

export default Pincode;
