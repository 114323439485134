import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className={styles.breadcrumbContainer}>
      <MUIBreadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          component={RouterLink}
          to="/"
          className={styles.link}
        >
          Home
        </Link>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <Typography key={to} color="text.primary" className={styles.current}>
              {value}
            </Typography>
          ) : (
            <Link
              key={to}
              color="inherit"
              component={RouterLink}
              to={to}
              className={styles.link}
            >
              {value}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
